import './style.scss'
import { Modal, Table, Tag } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useState } from 'react'
import * as homeApi from '@/api/home'

interface IProps {
  open: boolean
  onCancel?: () => void
}

const ContractListModal: FC<IProps> = (props) => {
  const { open, onCancel } = props
  const [list, setList] = useState([])

  useEffect(() => {
    if (open) {
      getContractList()
    }
  }, [open])

  const getContractList = async () => {
    const res = await homeApi.getContractList()
    setList(res?.list || [])
  }

  const downloadContract = (url: string) => {
    location.href = url
  }

  return (
    <>
      <Modal
        className="common-modal contract-list-modal"
        onCancel={onCancel}
        footer={null}
        width={900}
        open={open}
        title="合同管理"
      >
        <Table
          pagination={false}
          scroll={{ y: 400 }}
          columns={[
            {
              title: '合同编号',
              dataIndex: 'contract_no',
              width: 200
            },
            {
              title: '创建时间',
              dataIndex: 'create_time',
              width: 160,
              render: (time: number) => (time ? <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div> : '-')
            },
            {
              title: '合作开始时间',
              dataIndex: 'start_time',
              width: 160,
              render: (time: number) => (time ? <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div> : '-')
            },
            {
              title: '合作结束时间',
              dataIndex: 'end_time',
              width: 160,
              render: (time: number) => (time ? <div>{dayjs(time * 1000).format('YYYY-MM-DD HH:mm')}</div> : '-')
            },
            {
              title: '状态',
              dataIndex: 'status',
              width: 100,
              render: (status: number) => {
                if (status === 1) {
                  return <Tag>未生效</Tag>
                } else if (status === 2) {
                  return <Tag color="green">生效中</Tag>
                } else {
                  return <Tag color="gray">已过期</Tag>
                }
              }
            },
            {
              title: '操作',
              width: 100,
              render: (_, data: any) =>
                data.download_url ? <a onClick={downloadContract.bind(this, data.download_url)}>下载</a> : ''
            }
          ]}
          locale={{
            emptyText: <div style={{ padding: '40px 0' }}>暂无记录</div>
          }}
          dataSource={list || []}
        ></Table>
      </Modal>
    </>
  )
}

export default memo(ContractListModal)
