import './style.scss'
import { Menu } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Api from '@/api/home'
import { Home, Video } from '@/assets/svg'
import { Study } from '@/assets/svg/study'
import { User } from '@/assets/svg/user'
import { UserStore } from '@/global-states'
import { urlSource } from '@/libs/util'
import { AppstoreFilled, CopyrightCircleFilled, PayCircleFilled } from '@ant-design/icons'

import type { MenuProps } from 'antd'
type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  extra?: React.ReactNode
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    extra
  } as MenuItem
}

const LayoutMenu: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { userPackage, studyCenterClick } = UserStore
  const [data, setData] = useState<any[]>([])

  const showMarketNew = useMemo(() => {
    if (data?.length) {
      return true
    }
    return false
  }, [data])

  const showItems = useMemo(() => {
    return [
      getItem('数字人', '/home', <Home className="icon" />, undefined),
      getItem('声音克隆', '/clone', <CopyrightCircleFilled className="clone" />, undefined),
      getItem(
        <span className="ant-menu-title-content">
          数字市场 {showMarketNew ? <i className="menu-tag-red"></i> : null}
        </span>,
        '/market',
        <AppstoreFilled className="market" />,
        [
          getItem(
            '数字人市场',
            '/market/digital',
            null,
            undefined,
            showMarketNew ? <div className="menu-tag digital">new</div> : null
          ),
          getItem('声音社区', '/market/voice')
        ]
      ),
      getItem('作品管理', '/video', <Video className="icon" />, undefined),
      getItem('免费积分', '/invite', <PayCircleFilled className="icon" />, undefined),
      getItem(
        '学习中心',
        '/study/index.html',
        <Study className="study" />,
        undefined,
        !studyCenterClick ? <div className="menu-tag">new</div> : null
      ),
      getItem('个人中心', '/setting', <User className="icon" />, undefined)
    ]
  }, [studyCenterClick, showMarketNew])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const [merchandises] = await Promise.all([Api.getMerchandisesV2()])
    setData([...(merchandises?.list || []).filter((l: any) => l.status !== 2)])
  }

  const jumpToStudyCenter = () => {
    localStorage.setItem('studyCenter', 'true')
    UserStore.studyCenterClick = true
    window.open('/study/index.html')
  }

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === '/study/index.html') {
      jumpToStudyCenter()
    } else {
      navigate(e.key)
    }
  }

  return (
    <div className="layout-menu">
      <Menu
        theme="light"
        onClick={onClick}
        style={{ width: 220 }}
        selectedKeys={[pathname]}
        mode="inline"
        items={showItems}
      />

      {userPackage && (
        <>
          {userPackage.membership_expire_time && !userPackage.membership_expired ? (
            <div className="bottom">
              <img className="qrcode" src={urlSource('hiflys/qrcode/qrcode-vip.webp')} />
            </div>
          ) : (
            <div className="bottom">
              <img className="qrcode" src={urlSource('hiflys/qrcode/qrcode.webp')} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default LayoutMenu
