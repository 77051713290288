import './style.scss'
import { Input, message, Modal, Popover, Radio, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import * as Api from '@/api/account'
import * as homeApi from '@/api/home'
import Code from '@/assets/code.webp'
import { Check } from '@/assets/svg/check'
import TabCompany from '@/assets/tab-company.png'
import TabPersonal from '@/assets/tab-personal.png'
import Person from '@/assets/user.png'
import { IProduct, UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import ContractModal from '../ContractModal'
import PayModal from '../PayModal'

export interface IProductList {
  base: IProduct[]
  high: IProduct[]
}

interface IProps {
  open: boolean
  onCancel?: () => void
  onSuccess?: () => void
}

const PlanModal: FC<IProps> = (props) => {
  const { open, onCancel, onSuccess } = props
  const { phone, userPackage } = UserStore
  const [type, setType] = useState<'month' | 'year'>('month')
  const [tabIndex, setTabIndex] = useState(0)
  const [payModalOpen, setPayModalOpen] = useState(false)
  const [showProducts, setShowProducts] = useState<IProduct[]>([])
  const [payProduct, setPayProduct] = useState<IProduct>()
  const [convertModalOpen, setConvertModalOpen] = useState(false)
  const [code, setCode] = useState('')
  const [contractModalOpen, setContractModalOpen] = useState(false)

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  const baseProduct = useMemo(() => {
    return showProducts?.find((p) => p?.level === 10 && (type === 'month' ? p?.units === 1 : p?.units === 12))
  }, [showProducts, type])

  const highProduct = useMemo(() => {
    return showProducts?.find((p) => p?.level === 20 && (type === 'month' ? p?.units === 1 : p?.units === 12))
  }, [showProducts, type])

  const blackVipProduct = useMemo(() => {
    return showProducts?.find((p) => p?.level === 50 && (type === 'month' ? p?.units === 1 : p?.units === 12))
  }, [showProducts, type])

  const companyProduct = useMemo(() => {
    return showProducts?.find((p) => p?.level === 100 && (type === 'month' ? p?.units === 1 : p?.units === 12))
  }, [showProducts, type])

  useEffect(() => {
    if (open) {
      setType('month')
      getProductList()
    }
  }, [open])

  const getProductList = async () => {
    const { list }: { list: IProduct[] } = await Api.getProductList()

    const showProducts = [
      list.find((l) => l.level === 10 && l.units === 1),
      list.find((l) => l.level === 10 && l.units === 12),
      list.find((l) => l.level === 20 && l.units === 1),
      list.find((l) => l.level === 20 && l.units === 12),
      list.find((l) => l.level === 50 && l.units === 1),
      list.find((l) => l.level === 100 && l.units === 1),
      list.find((l) => l.level === 100 && l.units === 12)
    ]
    setShowProducts(showProducts as IProduct[])

    const vipProduct = list.find((l) => l.level === 50 && l.units === 1)

    if (vipProduct && vipProduct.sale_opening_time) {
      const time = vipProduct.sale_opening_time * 1000 - Date.now()
      if (time > 0) {
        setTimeout(() => {
          getProductList()
        }, time)
      }
    }
  }

  const onTabChange = (e: any) => {
    const dw = document.documentElement.clientWidth
    if (e.clientX >= dw / 2) {
      setTabIndex(1)
    }

    if (e.clientX <= dw / 2) {
      setTabIndex(0)
    }
  }

  const upgradeTip = (e: any) => {
    e.stopPropagation()
    Modal.warn({
      title: '升级规则',
      content: (
        <div className="declare-list" style={{ marginLeft: -20 }}>
          <ul style={{ paddingLeft: 10 }}>
            <li style={{ padding: '8px 0' }}>升级后功能权益立即生效, 升级后套餐的积分即刻到账;</li>
            <li style={{ padding: '8px 0' }}>旧套餐不做退补差价，已经给到的积分不会失效;</li>
            <li style={{ padding: '8px 0' }}>
              旧套餐剩余权益(会员时间等)自动延后，在升级套餐结束后继续生效。例如: 用户当前是基础单月会员(2030-02-01 ~
              2030-03-01)，该会员将在2030-03-01到期，如果用户在2030-02-15当天选择升级尊享单月会员，尊享单月会员立即生效(2030-02-15
              ~ 2030-03-15)，尊享单月会员在2030-03-15到期后，旧的基础单月会员还有15天权益，将于(2030-03-15 ~
              2030-04-01)继续生效。
            </li>
          </ul>
        </div>
      ),
      okText: '知道了'
    })
  }

  const useCode = async () => {
    await Api.useCode({ redeem_code: code })
    setConvertModalOpen(false)
    message.success('兑换成功')
    onSuccess?.()
  }

  const onSub = (product?: IProduct) => {
    onCancel?.()
    setPayProduct(product)
    setPayModalOpen(true)
  }

  const onSubCompany = async () => {
    const res = await homeApi.checkContract()
    onCancel?.()
    setPayProduct(companyProduct)
    if (res?.require_new_contract) {
      setContractModalOpen(true)
    } else {
      setPayModalOpen(true)
    }
  }

  return (
    <>
      <Modal className="plan-modal" footer={null} title={null} onCancel={() => onCancel?.()} open={open}>
        <div className="plan-content">
          <div className="plan-content-header">
            <img src={Person} />
            <div className="user-info">
              <div className="phone">
                <label>{phone}</label>
                {vip && !vipExpired && (
                  <label className="tag">{userPackage?.current_membership_package_title}会员</label>
                )}
              </div>
              {vip && userPackage ? (
                <>
                  {vipExpired ? (
                    <label className="text red">
                      套餐已于{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}过期
                    </label>
                  ) : (
                    <label className="text">
                      到期时间：{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                    </label>
                  )}
                </>
              ) : (
                <label className="text">未开通会员</label>
              )}
            </div>
          </div>
          <div className="plan-content-main" style={{ opacity: showProducts?.length ? 1 : 0 }}>
            <div className="tabs">
              {tabIndex === 0 ? (
                <img src={TabPersonal} onClick={onTabChange} />
              ) : (
                <img src={TabCompany} onClick={onTabChange} />
              )}
            </div>

            <Radio.Group
              value={type}
              onChange={(e) => {
                const type: 'month' | 'year' = e.target.value
                setType(type)
              }}
            >
              <Radio value={'month'}>月会员</Radio>
              <Radio value={'year'}>年会员</Radio>
            </Radio.Group>

            {tabIndex === 0 ? (
              <div className="plan-list">
                <div className="list base">
                  <div className="top">
                    <div className="title">基础版</div>
                    <div>解锁更多会员权益</div>
                  </div>
                  <div className="main">
                    <div className="price-item">
                      <div className="desc">
                        <label className="unit">¥</label>
                        <label className="price">
                          {((baseProduct?.discounted_unit_price || 0) * (baseProduct?.units || 0)) / 100}
                        </label>
                        <label className="time">/{type === 'month' ? '月' : '年'}</label>
                        {baseProduct?.discounted_unit_price !== baseProduct?.original_unit_price && (
                          <label className="orgin-price">
                            （原价{((baseProduct?.original_unit_price || 0) * (baseProduct?.units || 0)) / 100}/
                            {type === 'month' ? '月' : '年'}）
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="box-credits">
                      <div className="box-credits__num">
                        包含<div className="credit">{(baseProduct?.unit_credits || 0) * (baseProduct?.units || 0)}</div>
                        积分
                        <Popover
                          content={
                            <div className="common-popover w100">
                              <div>
                                积分用于数字人创作视频、高保真声音克隆等场景，不同场景的积分消耗也不同，详情请查看
                                <a
                                  target="_blank"
                                  href="https://lingverse.feishu.cn/sheets/IJeQsahvdhqX5btBSzycBKPRnr0"
                                  rel="noreferrer"
                                  style={{ paddingLeft: 2, textDecoration: 'underline' }}
                                >
                                  积分说明
                                </a>
                              </div>
                            </div>
                          }
                          placement="bottom"
                        >
                          <ExclamationCircleOutlined />
                        </Popover>
                      </div>
                      <div className="box-credits__time">约可生成{type === 'year' ? 360 : 30}分钟视频</div>
                    </div>
                    <div className="desc-list">
                      <div className="item">
                        <div className="left">形象复刻（限时免费）</div>
                        <div className="right">每月100次</div>
                      </div>
                      <div className="item">
                        <div className="left">声音克隆-基础版（限时免费）</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">数字人创作</div>
                        <div className="right">10积分/秒</div>
                      </div>

                      <div className="item">
                        <div className="left">输出分辨率</div>
                        <div className="right">最高1080P</div>
                      </div>

                      <div className="item">
                        <div className="left">积分充值</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">会员去水印</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                    </div>
                    {baseProduct?.status === 1 && (
                      <div
                        className="btn blue"
                        onClick={() => {
                          eventTracking('BuyNow')
                          onSub(baseProduct)
                        }}
                      >
                        立即购买
                      </div>
                    )}
                    {baseProduct?.status === 2 && (
                      <div
                        className="btn blue"
                        onClick={() => {
                          eventTracking('UpgradeNow')
                          onSub(baseProduct)
                        }}
                      >
                        立即升级
                      </div>
                    )}
                    {baseProduct?.status === 3 && (
                      <div
                        className="btn blue"
                        onClick={() => {
                          eventTracking('RenewNow')
                          onSub(baseProduct)
                        }}
                      >
                        立即续费
                      </div>
                    )}
                    {baseProduct?.status === 4 && <div className="btn gray">暂不支持会员降级</div>}
                  </div>
                </div>
                <div className="list high">
                  <div className="top">
                    <div className="title">尊享版</div>
                    <div>解锁更多会员权益</div>
                    <div className="top-tag orange">最受欢迎</div>
                  </div>
                  <div className="main">
                    <div className="price-item">
                      <div className="desc">
                        <label className="unit">¥</label>
                        <label className="price">
                          {((highProduct?.discounted_unit_price || 0) * (highProduct?.units || 0)) / 100}
                        </label>
                        <label className="time">/{type === 'month' ? '月' : '年'}</label>
                        {highProduct?.discounted_unit_price !== highProduct?.original_unit_price && (
                          <label className="orgin-price">
                            （原价{((highProduct?.original_unit_price || 0) * (highProduct?.units || 0)) / 100}/
                            {type === 'month' ? '月' : '年'}）
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="box-credits">
                      <div className="box-credits__num">
                        包含<div className="credit">{(highProduct?.unit_credits || 0) * (highProduct?.units || 0)}</div>
                        积分
                        <Popover
                          content={
                            <div className="common-popover w100">
                              <div>
                                积分用于数字人创作视频、高保真声音克隆等场景，不同场景的积分消耗也不同，详情请查看
                                <a
                                  target="_blank"
                                  href="https://lingverse.feishu.cn/sheets/IJeQsahvdhqX5btBSzycBKPRnr0"
                                  rel="noreferrer"
                                  style={{ paddingLeft: 2, textDecoration: 'underline' }}
                                >
                                  积分说明
                                </a>
                              </div>
                            </div>
                          }
                          placement="bottom"
                        >
                          <ExclamationCircleOutlined />
                        </Popover>
                      </div>
                      <div className="box-credits__time">约可生成{type === 'year' ? 360 : 30}分钟视频</div>
                    </div>
                    <div className="desc-list">
                      <div className="item">
                        <div className="left">包含基础版所有权益</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">形象复刻（限时免费）</div>
                        <div className="right">不限次数</div>
                      </div>

                      <div className="item">
                        <div className="left">数字人创作</div>
                        <div className="right">9积分/秒</div>
                      </div>

                      <div className="item">
                        <div className="left">输出分辨率</div>
                        <div className="right">最高4K</div>
                      </div>

                      <div className="item">
                        <div className="left">
                          声音克隆-高保真
                          <Popover
                            content={
                              <div className="common-popover w100">
                                <div>
                                  只需录制5~30s录音,即可极速拥有专属AI定制音色。高度还原真人音色特点、说话风格、口音和声学环境。
                                  <strong>
                                    尊享版会员有高保真声音的创建权和使用权，高保真声音需搭配尊享版会员使用。
                                  </strong>
                                  详情请查看
                                  <a
                                    target="_blank"
                                    href="https://lingverse.feishu.cn/docx/U6qiddhObonp56x3w1ucAlJ6nqb"
                                    rel="noreferrer"
                                    style={{ paddingLeft: 2, textDecoration: 'underline' }}
                                  >
                                    高保真声音介绍文档
                                  </a>
                                </div>
                              </div>
                            }
                            placement="top"
                          >
                            <ExclamationCircleOutlined />
                          </Popover>
                        </div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">优先队列</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                    </div>
                    {highProduct?.status === 1 && (
                      <div
                        className="btn purple"
                        onClick={() => {
                          eventTracking('BuyNow')
                          onSub(highProduct)
                        }}
                      >
                        立即购买
                      </div>
                    )}
                    {highProduct?.status === 2 && (
                      <div
                        className="btn purple"
                        onClick={() => {
                          eventTracking('UpgradeNow')
                          onSub(highProduct)
                        }}
                      >
                        立即升级
                        <div className="tips" onClick={upgradeTip}>
                          升级规则 <InfoCircleOutlined />
                        </div>
                      </div>
                    )}
                    {highProduct?.status === 3 && (
                      <div
                        className="btn purple"
                        onClick={() => {
                          eventTracking('RenewNow')
                          onSub(highProduct)
                        }}
                      >
                        立即续费
                      </div>
                    )}
                    {highProduct?.status === 4 && <div className="btn gray">暂不支持会员降级</div>}
                    {highProduct?.status === 5 && (
                      <div className="btn gray">
                        已售罄<span className="tag">敬请期待</span>
                      </div>
                    )}
                  </div>
                </div>
                {blackVipProduct && (
                  <div className="list vip">
                    <div className="top">
                      <div className="title">黑金版</div>
                      <div>解锁暗黑模式：无积分消耗不限量</div>
                      <div className="top-tag">高性价比</div>
                    </div>
                    <div className="main">
                      <div className="price-item">
                        <div className="desc">
                          <label className="unit">¥</label>
                          <label className="price">
                            {((blackVipProduct?.discounted_unit_price || 0) * (blackVipProduct?.units || 0)) / 100}
                          </label>
                          <label className="time">/{type === 'month' ? '月' : '年'}</label>
                          {blackVipProduct?.discounted_unit_price !== blackVipProduct?.original_unit_price && (
                            <label className="orgin-price">
                              （原价
                              {((blackVipProduct?.original_unit_price || 0) * (blackVipProduct?.units || 0)) / 100}/
                              {type === 'month' ? '月' : '年'}）
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="box-credits">
                        <div className="box-credits__num">
                          包含
                          <div className="credit">
                            {(blackVipProduct?.unit_credits || 0) * (blackVipProduct?.units || 0)}
                          </div>
                          积分
                          <Popover
                            content={
                              <div className="common-popover w100">
                                <div>
                                  积分用于数字人创作视频、高保真声音克隆等场景，不同场景的积分消耗也不同，详情请查看
                                  <a
                                    target="_blank"
                                    href="https://lingverse.feishu.cn/sheets/IJeQsahvdhqX5btBSzycBKPRnr0"
                                    rel="noreferrer"
                                    style={{ paddingLeft: 2, textDecoration: 'underline' }}
                                  >
                                    积分说明
                                  </a>
                                </div>
                              </div>
                            }
                            placement="bottom"
                          >
                            <ExclamationCircleOutlined />
                          </Popover>
                        </div>
                        <div className="box-credits__time">约可生成30分钟视频</div>
                      </div>
                      <div className="desc-list">
                        <div className="item">
                          <div className="left">包含尊享版所有权益</div>
                          <div className="right">
                            <Check />
                          </div>
                        </div>
                        <div className="item">
                          <div className="left">
                            暗黑模式：创作视频不消耗积分
                            <Popover
                              content={
                                <div className="common-popover w100">
                                  <div>
                                    暗黑模式常见问题请查看 常见问题-【黑金会员专区】
                                    <a
                                      target="_blank"
                                      href="https://lingverse.feishu.cn/docx/VxnydIE4ao5NCyx7fnycqPX7npd#share-Evkidf4qYoA4AfxzoZJclzRkndh"
                                      rel="noreferrer"
                                      style={{ paddingLeft: 2, textDecoration: 'underline' }}
                                    >
                                      常见问题-【黑金会员专区】
                                    </a>
                                  </div>
                                </div>
                              }
                              placement="bottom"
                            >
                              <ExclamationCircleOutlined />
                            </Popover>
                          </div>
                          <div className="right">
                            <Check />
                          </div>
                        </div>
                        <ul>
                          <li>
                            <label>·</label>
                            服务器繁忙时（例如工作日白天）任务优先级低于其他用户；繁忙时间段合成速度无法保证。若您对合成时效有要求，请勿选择此模式
                          </li>
                          <li>
                            <label>·</label>单个作品最大时长：180s
                          </li>
                          <li>
                            <label>·</label>本套餐不支持API方式接入
                          </li>
                        </ul>
                      </div>
                      {blackVipProduct?.status === 1 && (
                        <div
                          className="btn orange"
                          onClick={() => {
                            eventTracking('BuyNow')
                            onSub(blackVipProduct)
                          }}
                        >
                          立即购买
                          <span className="tag">限量发售</span>
                        </div>
                      )}
                      {blackVipProduct?.status === 2 && (
                        <div
                          className="btn orange"
                          onClick={() => {
                            eventTracking('UpgradeNow')
                            onSub(blackVipProduct)
                          }}
                        >
                          立即升级
                          <span className="tag">限量发售</span>
                          <div className="tips" onClick={upgradeTip}>
                            升级规则 <InfoCircleOutlined />
                          </div>
                        </div>
                      )}
                      {blackVipProduct?.status === 3 && (
                        <div
                          className="btn orange"
                          onClick={() => {
                            eventTracking('RenewNow')
                            onSub(blackVipProduct)
                          }}
                        >
                          立即续费
                          <span className="tag">限量发售</span>
                        </div>
                      )}
                      {blackVipProduct?.status === 4 && (
                        <div className="btn gray">
                          暂不支持会员降级<span className="tag">限量发售</span>
                        </div>
                      )}
                      {blackVipProduct?.status === 5 && (
                        <div className="btn gray">
                          {blackVipProduct.sale_opening_time ? '即将开售' : '已售罄'}
                          <span className="tag">
                            {userPackage?.current_membership_level === 50 ? '限量发售' : '敬请期待'}
                          </span>
                          {blackVipProduct.sale_opening_time && (
                            <span className="time">
                              {dayjs(blackVipProduct.sale_opening_time * 1000).format('YYYY-MM-DD HH:mm')}开售
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="plan-list">
                <div className="list company">
                  <div className="top">
                    <div className="title">企业API</div>
                    <div>解锁API接口调用</div>
                  </div>
                  <div className="main">
                    {companyProduct?.status === 5 ? (
                      <div className="contact">
                        <div className="left">
                          <img src={Code} />
                        </div>
                        <div className="right">
                          <div className="desc">
                            <label className="unit">¥</label>
                            <label className="price">
                              {((companyProduct?.discounted_unit_price || 0) * (companyProduct?.units || 0)) / 100}
                            </label>
                            <label className="time">起/{type === 'month' ? '月' : '年'}</label>
                          </div>
                          <div className="text">联系商务</div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="price-item">
                          <div className="desc">
                            <label className="unit">¥</label>
                            <label className="price">
                              {((companyProduct?.discounted_unit_price || 0) * (companyProduct?.units || 0)) / 100}
                            </label>
                            <label className="time">/{type === 'month' ? '月' : '年'}</label>
                            {companyProduct?.discounted_unit_price !== companyProduct?.original_unit_price && (
                              <label className="orgin-price">
                                （原价
                                {((companyProduct?.original_unit_price || 0) * (companyProduct?.units || 0)) / 100}/
                                {type === 'month' ? '月' : '年'}）
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="box-credits">
                          <div className="box-credits__num">不含积分</div>
                          <div className="box-credits__time">请根据实际使用情况，自行充值积分</div>
                        </div>
                      </>
                    )}

                    <div className="desc-list">
                      <div className="item">
                        <div className="left">更多会员权益</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">API接口调用</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">优先队列</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>

                      <div className="item">
                        <div className="left">在线服务支持</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                    </div>

                    {companyProduct?.status === 1 && (
                      <div
                        className="btn black"
                        onClick={() => {
                          eventTracking('BuyNow')
                          onSubCompany()
                        }}
                      >
                        立即购买
                      </div>
                    )}
                    {companyProduct?.status === 2 && (
                      <div
                        className="btn black"
                        onClick={() => {
                          eventTracking('UpgradeNow')
                          onSubCompany()
                        }}
                      >
                        立即升级
                        <div className="tips" onClick={upgradeTip}>
                          升级规则 <InfoCircleOutlined />
                        </div>
                      </div>
                    )}
                    {companyProduct?.status === 3 && (
                      <div
                        className="btn black"
                        onClick={() => {
                          eventTracking('RenewNow')
                          onSubCompany()
                        }}
                      >
                        立即续费
                      </div>
                    )}
                    {companyProduct?.status === 4 && <div className="btn gray">暂不支持会员降级</div>}
                    {companyProduct?.status === 5 && (
                      <Tooltip title="扫描上方二维码联系商务" trigger={['click']}>
                        <div className="btn black">联系商务</div>
                      </Tooltip>
                    )}
                  </div>
                </div>

                <div className="list company oem">
                  <div className="top">
                    <div className="title">企业OEM</div>
                    <div>解锁OEM定制方案</div>
                  </div>
                  <div className="main">
                    <div className="contact">
                      <div className="left">
                        <img src={Code} />
                      </div>
                      <div className="right">
                        <div className="desc">
                          <label className="desc-t">OEM定制方案</label>
                        </div>
                        <div className="text">联系商务</div>
                      </div>
                    </div>

                    <div className="desc-list">
                      <div className="item">
                        <div className="left">更多会员权益</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">OEM解决方案</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">在线服务支持</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                    </div>

                    <Tooltip title="扫描上方二维码联系商务" trigger={['click']}>
                      <div className="btn black">联系商务</div>
                    </Tooltip>
                  </div>
                </div>

                <div className="list company custom">
                  <div className="top">
                    <div className="title">更多定制需求</div>
                    <div>企业定制需求</div>
                  </div>
                  <div className="main">
                    <div className="contact">
                      <div className="left">
                        <img src={Code} />
                      </div>
                      <div className="right">
                        <div className="desc">
                          <label className="desc-t">企业定制需求</label>
                        </div>
                        <div className="text">联系商务</div>
                      </div>
                    </div>

                    <div className="desc-list">
                      <div className="item">
                        <div className="left">更多会员权益</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">企业个性化定制需求</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                      <div className="item">
                        <div className="left">在线服务支持</div>
                        <div className="right">
                          <Check />
                        </div>
                      </div>
                    </div>

                    <Tooltip title="扫描上方二维码联系商务" trigger={['click']}>
                      <div className="btn black">联系商务</div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <PayModal
        open={payModalOpen}
        onCancel={() => setPayModalOpen(false)}
        product={payProduct}
        onSuccess={() => onSuccess?.()}
      />

      <ContractModal
        open={contractModalOpen}
        onCancel={() => setContractModalOpen(false)}
        product={companyProduct}
        onSuccess={() => setPayModalOpen(true)}
      />

      <Modal
        okText="立即激活"
        cancelText="取消"
        title="请输入激活码"
        onCancel={() => setConvertModalOpen(false)}
        open={convertModalOpen}
        okButtonProps={{ disabled: !code }}
        onOk={useCode}
      >
        <div className="convert-content">
          <Input value={code} onChange={(e) => setCode(e.target.value)} />
        </div>
      </Modal>
    </>
  )
}

export default memo(PlanModal)
