export const PlayGray = (props: any) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 9.56574V3.43426C4 3.03491 4.44507 2.79671 4.77735 3.01823L9.37596 6.08397C9.67283 6.28189 9.67283 6.71811 9.37596 6.91603L4.77735 9.98177C4.44507 10.2033 4 9.96509 4 9.56574Z"
        fill="#ACA5A5"
      />
    </svg>
  )
}
