export const Study = (props: any) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      {...props}
    >
      <path d="M93.6 948c-15.2 0-27.2-12-27.2-27.2s12-27.2 27.2-27.2h863.2c15.2 0 27.2 12 27.2 27.2s-12 27.2-27.2 27.2H93.6zM952 108c-20-20.8-48-32-76.8-32h-272c-28.8 0-56 11.2-76 31.2l-5.6 4.8-4.8-4.8c-20-20-47.2-31.2-76-31.2H168c-60 0-108.8 48.8-108.8 108.8v562.4c0 15.2 12 27.2 27.2 27.2h330.4c48.8 0 76 37.6 80.8 44.8l1.6 1.6c0 0.8 0.8 0.8 1.6 1.6l4.8 4.8 0.8 0.8c4.8 3.2 9.6 4.8 15.2 4.8 2.4 0 5.6-0.8 8-1.6 0.8-0.8 1.6-0.8 2.4-0.8 1.6-0.8 2.4-0.8 4-1.6 0.8-0.8 1.6-0.8 3.2-2.4 0.8-0.8 2.4-1.6 3.2-3.2l1.6-2.4c0-0.8 0.8-0.8 0.8-1.6 0.8-1.6 28-44.8 80.8-44.8h330.4c15.2 0 27.2-12 27.2-27.2V184.8c0.8-28.8-10.4-56.8-31.2-76.8zM400 617.6H208.8c-15.2 0-27.2-12-27.2-27.2s12-27.2 27.2-27.2H400c15.2 0 27.2 12 27.2 27.2 0.8 15.2-12 27.2-27.2 27.2z m0-152.8H208.8c-15.2 0-27.2-12-27.2-27.2s12-27.2 27.2-27.2H400c15.2 0 27.2 12 27.2 27.2 0.8 15.2-12 27.2-27.2 27.2zM400 312H208.8c-15.2 0-27.2-12-27.2-27.2s12-27.2 27.2-27.2H400c15.2 0 27.2 12 27.2 27.2 0.8 15.2-12 27.2-27.2 27.2z m244-55.2h191.2c7.2 0 14.4 3.2 19.2 8 10.4 10.4 10.4 28 0 38.4-4.8 4.8-12 8-19.2 8H644c-15.2 0-27.2-12-27.2-27.2-0.8-14.4 12-26.4 27.2-27.2z m210.4 352.8c-4.8 4.8-12 8-19.2 8H644c-15.2 0-27.2-12-27.2-27.2s12-27.2 27.2-27.2h191.2c7.2 0 14.4 3.2 19.2 8 10.4 10.4 10.4 28 0 38.4z m-19.2-144.8H644c-15.2-0.8-27.2-12.8-26.4-28 0.8-14.4 12-26.4 26.4-26.4h191.2c15.2 0 27.2 12 27.2 27.2s-12 27.2-27.2 27.2z"></path>
    </svg>
  )
}
