import { cmsApi, interactApi } from '@/api'
import { UserStore } from '@/global-states'
import { Encrypt } from '@/libs/util'

export const getDigitalHumans = () => {
  return cmsApi.get('digital_humans')
}

export const getGlobalDigitalHumans = () => {
  return cmsApi.get('global_digital_humans')
}

export const getDigitalHumansDetail = (id: string | number) => {
  return cmsApi.get(`digital_humans/${id}`)
}

export const addDigitalHumans = (data: any) => {
  return cmsApi.post('digital_humans', data)
}

export const deleteDigitalHumans = (id: string | number) => {
  return cmsApi.delete(`digital_humans/${id}`)
}

export const updateDigitalHumans = (id: string | number, data: any) => {
  return cmsApi.patch(`digital_humans/${id}`, data)
}

export const getDigitalHumanGroups = () => {
  return cmsApi.get('digital_human_groups')
}

export const getGlobalDigitalHumanGroups = () => {
  return cmsApi.get('global_digital_human_groups')
}

export const getDigitalHumansByGroup = (id: number) => {
  return cmsApi.get(`digital_human_groups/${id}/digital_humans`)
}

export const addDigitalGroup = (data: any) => {
  return cmsApi.post(`digital_human_groups`, data)
}

export const updateDigitalGroup = (id: string | number, data: any) => {
  return cmsApi.patch(`digital_human_groups/${id}`, data)
}

export const deleteDigitalGroup = (id: string | number) => {
  return cmsApi.delete(`digital_human_groups/${id}`)
}

export const getDigitalInfo = (id: string | number) => {
  return cmsApi.get(`digital_humans/${id}/info`)
}

export const addCreations = (time: any, data: any) => {
  const { uid } = UserStore
  return cmsApi.post('creations', data, {
    headers: {
      'X-Cache-Key': Encrypt(`${uid}|${time}`)
    }
  })
}

export const getCreations = (data?: any) => {
  return cmsApi.get('creations', data)
}

export const updateCreations = (id: string | number, data: any) => {
  return cmsApi.patch(`creations/${id}`, data)
}

export const deleteCreations = (id: string | number) => {
  return cmsApi.delete(`creations/${id}`)
}

export const getShareAudios = () => {
  return cmsApi.get('shared_audios')
}

export const getGlobalTts = () => {
  return cmsApi.get('global_tts_voices')
}

export const getSelfTts = () => {
  return cmsApi.get('tts_voices')
}

export const voiceClone = (data: any) => {
  return cmsApi.post('tts_voices', data)
}

export const deleteVoice = (id: string | number) => {
  return cmsApi.delete(`tts_voices/${id}`)
}

export const updateVoice = (id: string | number, data: any) => {
  return cmsApi.put(`tts_voices/${id}`, data)
}

export const getVoiceDetail = (id: string | number) => {
  return cmsApi.get(`tts_voices/${id}/info`)
}

export const previewTts = (id: string | number, data: any) => {
  return cmsApi.post(`tts_voices/${id}/preview`, data)
}

export const getSelfTtsGroups = (data?: any) => {
  return cmsApi.get('tts_voice_groups', data)
}

export const addSelfTtsGroups = (data: any) => {
  return cmsApi.post('tts_voice_groups', data)
}

export const getGlobalTtsGroups = (data?: any) => {
  return cmsApi.get('global_tts_voice_groups', data)
}

export const updateVoiceGroup = (id: string | number, data: any) => {
  return cmsApi.patch(`tts_voice_groups/${id}`, data)
}

export const deleteVoiceGroup = (id: string | number) => {
  return cmsApi.delete(`tts_voice_groups/${id}`)
}

export const getVoicGroupDetail = (id: number, data?: any) => {
  return cmsApi.get(`tts_voice_groups/${id}/tts_voices`, data)
}

export const getVoicGroups = () => {
  return cmsApi.get('digital_human_groups')
}

export const getLanguageList = () => {
  return cmsApi.get('translatable_languages')
}

export const previewTtsDemo = (data: any) => {
  return cmsApi.post('tts_demos/demos', data)
}

export const creationVote = (id: string | number, data: any) => {
  return cmsApi.post(`creations/${id}/vote`, data)
}

// 获取克隆列表
export const getCloneableVoices = () => {
  return cmsApi.get('cloneable_advanced_tts_voices')
}

// 购买声音
export const buyCloneVoices = () => {
  return cmsApi.post('cloneable_advanced_tts_voices')
}

// 开始克隆
export const startCloneVoices = (id: string | number, data: any) => {
  return cmsApi.post(`cloneable_advanced_tts_voices/${id}/demos`, data)
}

// 完成克隆
export const completeCloneVoices = (id: string | number, data: any) => {
  return cmsApi.post(`cloneable_advanced_tts_voices/${id}/clone`, data)
}

// 数字人市场列表-竞拍
export const getAvatarMarkets = () => {
  return cmsApi.get(`psd/auctions`)
}

// 数字人市场列表-一口价
export const getMerchandises = () => {
  return cmsApi.get(`digital_human_merchandises`)
}

// 数字人市场列表-改版
export const getMerchandisesV2 = (tags?: string) => {
  return cmsApi.get(`digital_human_merchandises_v2${tags || ''}`)
}

// 数字人市场详情-改版
export const getMerchandisesDetail = (id: number) => {
  return cmsApi.get(`digital_human_merchandises/${id}`)
}

// 数字人市场列表-标签
export const getMerchandiseTags = () => {
  return cmsApi.get(`digital_human_merchandise_tags`)
}

// 数字人市场列表-一口价购买
export const buyMerchandises = (id: string | number) => {
  return cmsApi.post(`digital_human_merchandises/${id}/buy`)
}

// 数字人详情
export const getAvatarMarketDetail = (id: string | number) => {
  return cmsApi.get(`psd/auctions/${id}`)
}

// 数字人报价
export const avatarBidding = (id: string | number, data: any) => {
  return cmsApi.post(`psd/auctions/${id}/bidding`, data)
}

export const authRotate = (data: any) => {
  return cmsApi.post(`auth/rotate`, data)
}

// 获取未领取优惠券列表
export const getUnclaimedCoupons = () => {
  return cmsApi.get(`unclaimed_coupons`)
}

// 领取优惠券
export const receviceCoupon = (id: number) => {
  return cmsApi.post(`unclaimed_coupons/${id}/claim`)
}

// 已经领取优惠券列表
export const getClaimedCoupons = () => {
  return cmsApi.get(`claimed_coupons`)
}

// 获取广告位
export const getAdBoardList = (params: any) => {
  return cmsApi.get(`ad_boards`, params)
}

// 获取我的人脸
export const getFaceList = () => {
  return cmsApi.get(`avatar_fusion/faces`)
}

// 删除人脸
export const deleteFace = (id: number) => {
  return cmsApi.delete(`avatar_fusion/faces/${id}`)
}

// 获取推荐动作
export const getRecommendMotions = (params: any) => {
  return cmsApi.get(`avatar_fusion/recommended_motions`, params)
}

export const getMyActions = () => {
  return cmsApi.get(`avatar_fusion/private_motions`)
}

// 删除动作
export const deleteAction = (id: number) => {
  return cmsApi.delete(`avatar_fusion/private_motions/${id}`)
}

// 预览首帧
export const previewDigitalTask = (params: any) => {
  return cmsApi.post(`avatar_fusion/digital_human_preview_tasks`, params)
}

// 查询首帧结果
export const getPreviewDigitalTaskResult = (taskId: number) => {
  return cmsApi.get(`avatar_fusion/digital_human_preview_tasks/${taskId}`)
}

export const getNationalGenders = () => {
  return cmsApi.get(`avatar_fusion/tags/nationality_genders`)
}

export const getAgeGroups = () => {
  return cmsApi.get(`avatar_fusion/tags/age_groups`)
}

export const getHairStyles = () => {
  return cmsApi.get(`avatar_fusion/tags/hair_styles`)
}

export const getHairColors = () => {
  return cmsApi.get(`avatar_fusion/tags/hair_colors`)
}

export const getFaceCost = () => {
  return cmsApi.get(`avatar_fusion/cost/face_task`)
}

export const getPromptTemplates = () => {
  return cmsApi.get(`avatar_fusion/tags/additional_prompt_templates`)
}

export const submitFaceTask = (params: any) => {
  return cmsApi.post(`avatar_fusion/face_tasks`, params)
}

export const submitHumanTasks = (params: any) => {
  return cmsApi.post(`avatar_fusion/digital_human_tasks`, params)
}

export const getHumanTasks = () => {
  return cmsApi.get(`avatar_fusion/digital_human_tasks`)
}

export const submitPrivateAction = (params: any) => {
  return cmsApi.post(`avatar_fusion/private_motions`, params)
}

export const getQuestionCompleted = () => {
  return cmsApi.get(`questionnaire1_completed`)
}

export const getQuestions = () => {
  return cmsApi.get(`questionnaire1`)
}

export const submitQa = (params: any) => {
  return cmsApi.put(`questionnaire1`, params)
}

// 获取数字演员列表
export const getDigitalList = () => {
  return interactApi.get('digital_actor')
}

// 创建数字演员
export const addDigital = (data: any) => {
  return interactApi.post('digital_actor', data)
}

// 修改数字演员
export const editDigital = (id: number, data: any) => {
  return interactApi.patch(`digital_actor/${id}`, data)
}

// 删除数字演员
export const deleteDigital = (id: number) => {
  return interactApi.delete(`digital_actor/${id}`)
}

// 获取数字演员对应视频
export const getDigitalAvatar = (id: number) => {
  return interactApi.get(`digital_actor/${id}`)
}

// 添加数字演员视频
export const addDigitalAvatar = (id: number, data: any) => {
  return interactApi.post(`digital_actor/${id}/avatar`, data)
}

// 修改数字演员视频
export const editDigitalAvatar = (digital_actor_id: number, avatar_id: number, data: any) => {
  return interactApi.patch(`digital_actor/${digital_actor_id}/avatar/${avatar_id}`, data)
}

// 下载模型视频
export const downloadAvatarModel = (digital_actor_id: number, avatar_id: number) => {
  return interactApi.get(`digital_actor/${digital_actor_id}/download/${avatar_id}`)
}

// 删除数字演员视频
export const deleteDigitalAvatar = (digital_actor_id: number, avatar_id: number) => {
  return interactApi.delete(`digital_actor/${digital_actor_id}/avatar/${avatar_id}`)
}

// 获取知识库列表
export const getAgentList = () => {
  return interactApi.get('agents')
}

// 添加知识库
export const addAgent = (data: any) => {
  return interactApi.post('agents', data)
}

// 获取知识库详情
export const getAgentDetail = (id: number) => {
  return interactApi.get(`agents/${id}`)
}

// 修改知识库
export const updateAgent = (id: number, data: any) => {
  return interactApi.put(`agents/${id}`, data)
}

// 删除知识库
export const deleteAgent = (id: number) => {
  return interactApi.delete(`agents/${id}`)
}

// 获取连线列表
export const getInteractionList = (data?: any) => {
  return interactApi.get('interaction', data)
}

// 新建连线
export const addInteraction = (data: any) => {
  return interactApi.post(`interaction`, data)
}

// 编辑连线
export const editInteraction = (id: number, data: any) => {
  return interactApi.patch(`interaction/${id}`, data)
}

// 连线详情
export const getInteractionDetail = (id: number) => {
  return interactApi.get(`interaction/${id}`)
}

// 删除连线
export const deleteInteraction = (id: number) => {
  return interactApi.delete(`interaction/${id}`)
}

// 开始连线
export const startInteraction = (id: number, data: any) => {
  return interactApi.post(`interaction/${id}/start`, data)
}

// 获取连线信息连线
export const getPlayingInfo = (id: number) => {
  return interactApi.get(`interaction/${id}/playing_info`)
}

// 结束连线
export const stopInteraction = (id: number) => {
  return interactApi.post(`interaction/${id}/stop`)
}

// 获取声音社区标签
export const getCommunityVoiceTags = () => {
  return cmsApi.get(`community_tts_voice_tags`)
}

// 获取订阅声音
export const getBookmarkedCommunityVoice = () => {
  return cmsApi.get(`my_bookmarked_community_tts_voices`)
}

// 订阅
export const updateBookmarkedCommunityVoice = (data: any) => {
  return cmsApi.post(`my_bookmarked_community_tts_voices`, data)
}

// 取消订阅
export const delteBookmarkedCommunityVoice = (id: number) => {
  return cmsApi.delete(`my_bookmarked_community_tts_voices/${id}`)
}

// 获取社区声音列表
export const getCommunityVoices = () => {
  return cmsApi.get(`community_tts_voices`)
}

// 获取社区声音详情
export const getCommunityVoiceDetail = (id: number) => {
  return cmsApi.get(`community_tts_voices/${id}/detail`)
}

// 获取社区声音详情列表
export const getCommunityVoiceList = (id: number) => {
  return cmsApi.get(`community_tts_voices/${id}/tts_voices`)
}

// 社区声音预览
export const previewCommunityVoice = (id: number, voice_id: number, data: any) => {
  return cmsApi.post(`community_tts_voices/${id}/tts_voices/${voice_id}/preview`, data)
}

// 获取照片生成数字人具体信息
export const getPictureAvatarInfo = () => {
  return cmsApi.get(`vibrant_live_portrait_info`)
}

// 校验合同
export const checkContract = () => {
  return cmsApi.get(`need_new_enterprise_membership_contract`)
}

// 签署合同
export const addNewContract = (data: any) => {
  return cmsApi.post(`enterprise_membership_contracts`, data)
}

// 合同列表
export const getContractList = (data?: any) => {
  return cmsApi.get(`enterprise_membership_contracts`, data)
}
