import './style.scss'
import { Button, Checkbox, Input, message, Modal, Popover, QRCode } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import * as Api from '@/api/account'
import * as HomeApi from '@/api/home'
import Person from '@/assets/user.png'
import { IProduct, UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { AlipayOutlined, ExclamationCircleOutlined, WechatOutlined } from '@ant-design/icons'
import Coupon from '../Coupon'

interface IProps {
  product?: IProduct
  open: boolean
  hiddenInviteCode?: boolean
  onCancel?: () => void
  onSuccess?: () => void
}

const PayModal: FC<IProps> = (props) => {
  const { product, open, hiddenInviteCode, onCancel, onSuccess } = props
  const { phone, userPackage } = UserStore
  const [payMethod, setPayMethod] = useState(1)
  const [loading, setLoading] = useState(false)
  const [payUrl, setPayUrl] = useState('')
  const [checked, setChecked] = useState(true)
  const payTimer = useRef<any>()
  const [affiliateCode, setAffiliateCode] = useState('')
  const [coupon, setCoupon] = useState<any>()
  const [couponOpen, setCouponOpen] = useState(false)
  const [couponId, setCouponId] = useState<number>()

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  useEffect(() => {
    setPayMethod(1)
    setPayUrl('')
    clearPayTimer()
    if (open) {
      getClaimedCoupons()
      getUnclaimedCoupons()
      const code = sessionStorage.getItem('code')
      if (code) {
        setAffiliateCode(code)
      } else {
        setAffiliateCode('')
      }
    }
  }, [open])

  const getClaimedCoupons = async () => {
    const res = await HomeApi.getClaimedCoupons()
    const list = res?.list?.filter((l: any) => l.status === 2)
    if (list?.length) {
      setCoupon(list[0])
    }
  }

  const getUnclaimedCoupons = async () => {
    const res = await UserStore.getUnclaimedCoupons()
    if (res?.id) {
      setCouponOpen(true)
      setCouponId(res.id)
    }
  }

  const changePayMethod = (method: number) => {
    setPayMethod(method)
  }

  const toPay = async () => {
    setLoading(true)

    try {
      const { pay_order_id } = await Api.orderCreate({
        package_id: product?.id,
        client_type: 1,
        affiliate_code: affiliateCode
      })

      const buyParams = {
        orderId: pay_order_id,
        payType: payMethod === 0 ? 'ALIPAY' : 'WECHATPAY'
      }

      eventTracking('PayNow', buyParams)

      const buyRes = await Api.paymentInit(buyParams)
      if (payMethod === 1) {
        setPayUrl(buyRes?.pay_list.wechatpayNative?.code_url)
        paymentCheck(pay_order_id)
        payTimer.current = setInterval(() => {
          paymentCheck(pay_order_id)
        }, 3000)
      } else {
        location.href = buyRes?.pay_list?.alipay
      }
    } catch (e: any) {
      message.destroy()
      if (e.code === 14) {
        message.error('套餐已失效，无法购买')
      } else {
        message.error(e.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const paymentCheck = async (orderId: string) => {
    const res = await Api.paymentCheck({
      orderId
    })
    if (res?.is_pay === 1) {
      message.success('支付成功')
      onCancel?.()
      onSuccess?.()
    }
  }

  const clearPayTimer = () => {
    if (payTimer.current) {
      clearInterval(payTimer.current)
      payTimer.current = undefined
    }
  }

  return (
    <>
      <Modal className="pay-modal" footer={null} width={660} onCancel={() => onCancel?.()} open={open}>
        <div className="pay-content">
          <div className="pay-content-header">
            <img src={Person} />
            <div className="user-info">
              <div className="phone">
                <label>{phone}</label>
                {vip && !vipExpired && (
                  <label className="tag">{userPackage?.current_membership_package_title}会员</label>
                )}
              </div>
              {vip && userPackage ? (
                <>
                  {vipExpired ? (
                    <label className="text red">
                      套餐已于{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}过期
                    </label>
                  ) : (
                    <label className="text">
                      到期时间：{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                    </label>
                  )}
                </>
              ) : (
                <label className="text">未开通会员</label>
              )}
            </div>
          </div>
          <div className="pay-content-main">
            {!payUrl ? (
              <>
                <div className="pay-methods">
                  <div className="name">支付方式</div>
                  <div className="pay-list">
                    <div
                      className={`item wechat ${payMethod === 1 ? 'actived' : ''}`}
                      onClick={changePayMethod.bind(this, 1)}
                    >
                      <WechatOutlined />
                      <div>微信支付</div>
                    </div>
                    <div
                      className={`item alipay ${payMethod === 0 ? 'actived' : ''}`}
                      onClick={changePayMethod.bind(this, 0)}
                    >
                      <AlipayOutlined />
                      <div>支付宝</div>
                    </div>
                  </div>
                </div>

                {!!product?.details?.length && (
                  <div className="tips">
                    <div className="name">购买获得以下内容:</div>
                    <ul>
                      {product.details.map((d, index) => (
                        <li key={index}>{d}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {!hiddenInviteCode && (
                  <div className="invite-code">
                    <div className="name">
                      输入邀请码（非必填）
                      <Popover placement="topLeft" content={'成功支付后,你和邀请人账号都会获得赠送积分'}>
                        <ExclamationCircleOutlined />
                      </Popover>
                    </div>
                    <Input
                      value={affiliateCode}
                      placeholder="请输入邀请码"
                      onChange={(e) => setAffiliateCode(e.target.value)}
                    />
                  </div>
                )}

                {coupon && (
                  <div className="pay-coupon">
                    <div className="name">优惠</div>
                    <div className="detail">
                      <label>- ¥ {coupon.deduct_amount / 100}</label>{' '}
                      <label className="desc">{coupon.description}</label>
                    </div>
                  </div>
                )}

                <div className="plan">
                  <div className="name">应付金额</div>
                  {coupon ? (
                    <div className="money">
                      {(product?.total_price || 0) > coupon.deduct_amount ? (
                        <label>￥{((product?.total_price || 0) - coupon.deduct_amount) / 100}</label>
                      ) : (
                        <label>￥{(product?.total_price || 0) / 100}</label>
                      )}
                      <label className="origin-price">￥{(product?.total_price || 0) / 100}</label>
                    </div>
                  ) : (
                    <div className="money">￥{(product?.total_price || 0) / 100}</div>
                  )}
                </div>

                <div className="btn">
                  <Button disabled={!checked} loading={loading} type="primary" onClick={toPay}>
                    立即支付
                  </Button>
                </div>
                <div className="checkbox">
                  <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}></Checkbox>
                  <div>
                    我已阅读并同意
                    <a href="/service_agreement.html" target="_blank">
                      《服务协议》
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <div className="pay-qrcode">
                <div className="pay-desc">
                  <div>微信扫一扫付款（元）</div>
                  {coupon ? (
                    <>
                      {(product?.total_price || 0) > coupon.deduct_amount ? (
                        <div className="mount">{((product?.total_price || 0) - coupon.deduct_amount) / 100}</div>
                      ) : (
                        <div className="mount">{(product?.total_price || 0) / 100}</div>
                      )}
                    </>
                  ) : (
                    <div className="mount">{(product?.total_price || 0) / 100}</div>
                  )}
                </div>
                <QRCode value={payUrl} />
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Coupon
        open={couponOpen}
        couponId={couponId}
        onCancel={() => setCouponOpen(false)}
        onSuccess={() => {
          getClaimedCoupons()
          setCouponOpen(false)
        }}
      />
    </>
  )
}

export default memo(PayModal)
