import './style.scss'
import { Button, message, Modal } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useMemo, useState } from 'react'
import * as homeApi from '@/api/home'
import Person from '@/assets/user.png'
import { IProduct, UserStore } from '@/global-states'
import { BankOutlined, UserOutlined } from '@ant-design/icons'

interface IProps {
  product?: IProduct
  open: boolean
  onCancel?: () => void
  onSuccess?: () => void
}

const ContractModal: FC<IProps> = (props) => {
  const { open, onCancel, onSuccess } = props
  const { phone, userPackage } = UserStore
  const [role, setRole] = useState(0)
  const [loading, setLoading] = useState(false)

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  const signContract = async () => {
    try {
      setLoading(true)
      const res = await homeApi.addNewContract({
        is_corp: role === 0 ? false : true
      })
      if (res?.signing_url) {
        window.open(res.signing_url)
      }
    } finally {
      setLoading(false)
    }
  }

  const checkContract = async () => {
    homeApi.getContractList()
    const res = await homeApi.checkContract()
    if (!res?.require_new_contract) {
      onCancel?.()
      onSuccess?.()
    } else {
      message.warning('您当前没有生效中的合同，请先签署合同')
    }
  }

  return (
    <>
      <Modal className="contract-modal" footer={null} width={780} onCancel={() => onCancel?.()} open={open}>
        <div className="pay-content">
          <div className="pay-content-header">
            <img src={Person} />
            <div className="user-info">
              <div className="phone">
                <label>{phone}</label>
                {vip && !vipExpired && (
                  <label className="tag">{userPackage?.current_membership_package_title}会员</label>
                )}
              </div>
              {vip && userPackage ? (
                <>
                  {vipExpired ? (
                    <label className="text red">
                      套餐已于{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}过期
                    </label>
                  ) : (
                    <label className="text">
                      到期时间：{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                    </label>
                  )}
                </>
              ) : (
                <label className="text">未开通会员</label>
              )}
            </div>
          </div>
          <div className="contract-content">
            <div className="title">购买API套餐需与我司签署服务合同，请选择身份签署：</div>
            <div className="role">
              <div className={`role-item ${role === 0 ? 'actived' : ''}`} onClick={() => setRole(0)}>
                <UserOutlined />
                <div>个人</div>
              </div>
              <div className={`role-item ${role === 1 ? 'actived' : ''}`} onClick={() => setRole(1)}>
                <BankOutlined />
                <div>企业</div>
              </div>
            </div>

            <div className="friedly-tips">
              <div className="tips-title">温馨提示</div>
              <div>1. 本产品一经购买，权益立即生效；</div>
              <div>2. 本产品购买后，不支持退款退货； </div>
              <div>
                3. 如您有大额采购、高并发等定制化需求，请您随时联系我们，我们会有专门商务同事与您沟通，感谢您的理解。
              </div>
            </div>

            <div className="bottom">
              <div className="tip">为保障您的权益，请仔细查阅并与我司签署服务合同</div>

              <Button type="primary" loading={loading} onClick={signContract}>
                签署在线合同
              </Button>
              <Button type="text" onClick={checkContract}>
                我已完成签署，下一步
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default memo(ContractModal)
