import './style.scss'
import { Button, Checkbox, Input, Select, Space } from 'antd'
import axios from 'axios'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Api from '@/api/login'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { LoginStore } from '../../states'

const MobileLogin: FC = () => {
  const { phone, agreementChecked } = LoginStore
  const [mobileError, setMobileError] = useState(false)
  const [captcha, setCaptcha] = useState('')
  const [captchaError, setCaptchaError] = useState(false)
  const [captchaButtonDisableMessage, setCaptchaButtonDisableMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const picCaptchaRef = useRef<any>()
  const env = import.meta.env
  const [countryCode, setCountryCode] = useState('86')
  let captchaCode: any
  const phoneLimitLength = useMemo(() => {
    ;(window as any).countryCode = countryCode
    if (countryCode == '852' || countryCode == '853') {
      return 8
    }
    if (countryCode == '886') {
      return 9
    }
    return 11
  }, [countryCode])

  useEffect(() => {
    initCaptcha()
  }, [])

  useEffect(() => {
    setMobileError(phone.length > phoneLimitLength)
  }, [phone, phoneLimitLength])

  useEffect(() => {
    setCaptchaError(captcha.length > 6)
  }, [captcha])

  const initCaptcha = () => {
    ;(window as any).initAliyunCaptcha({
      SceneId: '4cgjkj1e', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: '8imnku', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 360,
        height: 40
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      region: 'cn' //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
    })
  }

  const captchaVerifyCallback = async (captchaVerifyParam: any) => {
    const res: any = await axios.post(`${env.VITE_API_BASE_URL_AUTH}api/v1/meta/check_captcha`, {
      params: captchaVerifyParam, // 验证码参数
      phone: (window as any).phone
    })

    const code = res?.data?.code
    if (code) {
      getCaptcha(code)
    }

    const verifyResult = {
      captchaResult: !!code, // 验证码验证是否通过，boolean类型，必选
      bizResult: !!code
    }
    return verifyResult
  }

  const onBizResultCallback = (bizResult: any) => {
    if (bizResult === true) {
      console.log('业务验证通过！')
    } else {
      console.log('业务验证不通过！')
    }
  }

  const getInstance = (instance: any) => {
    captchaCode = instance
  }

  const onMobileChange = (e: any) => {
    ;(window as any).phone = e.target.value.replaceAll(/\D/g, '')
    LoginStore.phone = e.target.value.replaceAll(/\D/g, '')
  }

  const oncCaptchaChange = (e: any) => {
    setCaptcha(e.target.value.replaceAll(/\D/g, ''))
  }

  const onAgreeChange = (e: any) => {
    LoginStore.agreementChecked = e.target.checked
  }

  // 获取验证码
  const getCaptcha = async (check_code?: string) => {
    const res = await Api.sendSms({
      phone: (window as any).phone || phone,
      code_type: 'REORLOGINCODE',
      check_code,
      country_code: (window as any).countryCode || countryCode
    })
    if (res.code === 201) {
      picCaptchaRef.current?.click()
    } else {
      let index = 60
      setCaptchaButtonDisableMessage(`已发送 ${index}S`)
      const timer = setInterval(() => {
        index--
        setCaptchaButtonDisableMessage(`已发送 ${index}S`)
        if (index < 1) {
          clearInterval(timer)
          setCaptchaButtonDisableMessage('')
        }
      }, 1000)
    }
  }

  const onPressEnter = () => {
    if (phone.length === phoneLimitLength && captcha.length === 6 && agreementChecked) {
      doLogin()
    }
  }

  // 登陆
  const doLogin = async () => {
    setLoading(true)
    const invite_code = sessionStorage.getItem('code')
    const bd_vid = sessionStorage.getItem('bd_vid')
    try {
      const user = await Api.login({
        phone,
        code: captcha,
        type: 'CODE',
        invite_code,
        country_code: countryCode,
        ad_vid: bd_vid,
        ad_type: bd_vid ? 'BAIDU_SEM' : ''
      })
      eventTracking('user_login')
      if (!user?.token) {
        throw new Error('登录失败')
      }
      UserStore.setUserToken(user)
      UserStore.checkUserPackage()
      UserStore.getQaCompleted()
      const redirectUrl = sessionStorage.getItem('redirectUrl')
      if (invite_code && redirectUrl) {
        sessionStorage.removeItem('redirectUrl')
        setTimeout(() => {
          navigate(`${redirectUrl}?code=${invite_code}`)
        })
      } else {
        navigate('/home')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="mobile-login">
      <div className="login-phone">
        <Select
          popupClassName="coutry-select"
          value={countryCode}
          onChange={(v) => setCountryCode(v)}
          options={[
            {
              label: '+86',
              value: '86',
              desc: '中国'
            },
            {
              label: '+886',
              value: '886',
              desc: '中国台湾'
            },
            {
              label: '+852',
              value: '852',
              desc: '中国香港'
            },
            {
              label: '+853',
              value: '853',
              desc: '中国澳门'
            }
          ]}
          optionRender={(option) => (
            <Space>
              <label style={{ display: 'inline-block', width: '80px', cursor: 'pointer' }}>{option.data.label}</label>
              {option.data.desc}
            </Space>
          )}
        ></Select>
        <Input
          className="input-with-hint"
          value={phone}
          maxLength={20}
          onChange={onMobileChange}
          placeholder={`请输入 ${phoneLimitLength}位手机号`}
          status={mobileError ? 'error' : undefined}
        />
      </div>
      <div className="login-capt">
        <Input
          className="input-with-hint"
          value={captcha}
          maxLength={20}
          onChange={oncCaptchaChange}
          status={captchaError ? 'error' : undefined}
          placeholder="请输入 6 位验证码"
          onPressEnter={onPressEnter}
        />
        <Button
          className="borderless btn-capt"
          onClick={() => getCaptcha()}
          type="primary"
          disabled={!!captchaButtonDisableMessage || phone.length !== phoneLimitLength}
        >
          {captchaButtonDisableMessage || '获取验证码'}
        </Button>
      </div>

      {/* <div className="op">
        <Checkbox checked={rememberAccount} onChange={onRememberAccountChange}>
          <label>记住账号</label>
        </Checkbox>
        <label className="switch" onClick={switchLoginType}>
          密码登录
        </label>
      </div> */}

      <Button
        className="mobile-login-button"
        type="primary"
        disabled={phone.length !== phoneLimitLength || captcha.length !== 6 || !agreementChecked}
        onClick={doLogin}
        loading={loading}
      >
        登录
      </Button>

      <div className="login-agree">
        <Checkbox checked={agreementChecked} onChange={onAgreeChange}>
          <label>我已阅读并同意</label>
          <a href="/eula.html" target="_blank">
            《用户协议》
          </a>
          <label>与</label>
          <a href="/privacy_agreement.html" target="_blank">
            《隐私条款》
          </a>
        </Checkbox>
      </div>

      <Button ref={picCaptchaRef} id="captcha-button" style={{ display: 'none' }}>
        图片验证码
      </Button>

      <div id="captcha-element"></div>
    </div>
  )
}

export default memo(MobileLogin)
