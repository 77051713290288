import './style.scss'
import { Modal } from 'antd'
import { FC } from 'react'
import { urlSource } from '@/libs/util'

interface IProps {
  open: boolean
  onCancel: () => void
}

const ProductModal: FC<IProps> = (props) => {
  const { open, onCancel } = props
  return (
    <Modal
      open={open}
      title={null}
      footer={null}
      className="tab-modal interact-product-modal"
      width={1200}
      onCancel={onCancel}
    >
      <div className="tab-modal-content">
        <div className="product-title">套餐介绍</div>
        <div className="product-list">
          <img src={urlSource('hiflys/interact/product-local.png')} />
          <img src={urlSource('hiflys/interact/product-online.png')} />
        </div>
        <img className="qrcode" src={urlSource('hiflys/interact/qrcode.png')} />
      </div>
    </Modal>
  )
}

export default ProductModal
