import './style.scss'
import { Modal } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as Api from '@/api/account'
import { IProduct, UserStore } from '@/global-states'
import { eventTracking, urlSource } from '@/libs/util'
import { CloseOutlined } from '@ant-design/icons'
import PayModal from '../PayModal'

interface IProps {
  open: boolean
  onCancel?: () => void
}
const ActivityModal: FC<IProps> = (props) => {
  const { open, onCancel } = props
  const [product, setProductList] = useState<IProduct[]>()
  const [payModalOpen, setPayModalOpen] = useState(false)
  const [payProduct, setPayProduct] = useState<IProduct>()
  const [showClose, setShowClose] = useState(false)

  const getProductList = async () => {
    const { list }: { list: IProduct[] } = await Api.getProductList()
    setProductList(list?.filter((p) => p.activity?.includes('activity')) || [])
  }

  const toPay = (e: any) => {
    const segmentsX = product?.length || 1 // 水平分割份数
    // 获取图片的位置信息
    const rect = e.target.getBoundingClientRect()
    const imageWidth = rect.width * 0.75

    // 获取点击位置
    const clickX = e.clientX - (rect.left + rect.width * 0.125) // 相对于图片左边的点击位置

    // 判断点击位置的区域
    const xRegion = clickX / imageWidth

    // 动态计算点击所在的 x 区域
    const segmentWidth = 1 / segmentsX
    for (let i = 1; i <= segmentsX; i++) {
      if (xRegion > 0 && xRegion <= i * segmentWidth) {
        setPayProduct(product?.[i - 1])
        onCancel?.()
        eventTracking(`ActivityModalClick-${i}`)
        setPayModalOpen(true)
        break
      }
    }
  }

  const closeModal = () => {
    onCancel?.()
    UserStore.updateUserPackage()
  }

  useEffect(() => {
    if (open) {
      eventTracking('ActivityModaShow')
      sessionStorage.setItem('activity', 'false')
      getProductList()
    }
  }, [open])

  return (
    <>
      <Modal
        maskClosable={false}
        className="activity-modal"
        footer={null}
        onCancel={closeModal}
        open={open}
        closeIcon={null}
      >
        <div className="activity-modal-content">
          {showClose && (
            <button type="button" aria-label="Close" className="ant-modal-close" onClick={closeModal}>
              <span className="ant-modal-close-x">
                <CloseOutlined />
              </span>
            </button>
          )}
          <img
            src={urlSource('hiflys/personal-activity/images/activity-modal-new.webp')}
            onClick={toPay}
            onLoad={() => {
              setShowClose(true)
            }}
          />
        </div>
      </Modal>
      <PayModal
        open={payModalOpen}
        product={payProduct}
        onCancel={() => {
          setPayModalOpen(false)
          closeModal()
        }}
        hiddenInviteCode={true}
      />
    </>
  )
}

export default memo(ActivityModal)
