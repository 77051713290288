import './index.scss'
import { Dropdown, MenuProps } from 'antd'
import { FC, useEffect, useState } from 'react'
import QaIcon from '@/assets/qa-icon.webp'
import { Agreement } from '@/assets/svg/agreement'
import { Study } from '@/assets/svg/study'
import { Video } from '@/assets/svg/video'
import { UserStore } from '@/global-states'
import { eventTracking, urlSource } from '@/libs/util'
import {
  CaretDownOutlined,
  MessageFilled,
  ProjectFilled,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  SoundFilled
} from '@ant-design/icons'
import QaModal from '../QaModal'
import VideoModal from '../VideoModal'

const CustomerService: FC = () => {
  const [open, setOpen] = useState(false)
  const [qaModalOpen, setQaModalOpen] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const { showQuestion, showQuestionModal, versionNumber } = UserStore

  useEffect(() => {
    if (UserStore.getToken() && !location.href.includes('login')) {
      getQaCompleted()
    }
  }, [])

  useEffect(() => {
    if (showQuestionModal && !localStorage.getItem('qaModal') && !location.href.includes('setting')) {
      setQaModalOpen(true)
    }
  }, [showQuestionModal])

  const getQaCompleted = async () => {
    UserStore.getQaCompleted()
  }

  const readNew = () => {
    if (versionNumber > 0) {
      UserStore.versionNumber = versionNumber - 1
      localStorage.setItem('version-20241107', `${versionNumber - 1}`)
    }
  }

  const items: MenuProps['items'] = [
    {
      label: '新手引导',
      key: '0',
      icon: <Video />
    },
    {
      label: (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://lingverse.feishu.cn/wiki/SAENwOqqziHLSTkAREwcWt0bnKe?fromScene=spaceOverview"
          onClick={readNew}
        >
          功能更新
          {versionNumber > 0 && <i className="tip"></i>}
        </a>
      ),
      key: '5',
      icon: <SoundFilled />
    },
    {
      label: (
        <a target="_blank" rel="noreferrer" href="https://lingverse.feishu.cn/docx/FPIwdnsV1o3RcexqwuHcpit7n2g">
          使用手册
        </a>
      ),
      key: '1',
      icon: <Agreement />
    },
    {
      label: (
        <a target="_blank" rel="noreferrer" href="/study/index.html">
          学习中心
        </a>
      ),
      key: 'study',
      icon: <Study className="customer-study" />
    },
    {
      label: (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://lingverse.feishu.cn/docx/VxnydIE4ao5NCyx7fnycqPX7npd?fromScene=spaceOverview&create_from=create_doc_to_wiki&302from=wiki"
        >
          常见问题
        </a>
      ),
      key: '2',
      icon: <QuestionCircleFilled />
    },
    {
      label: (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://lingverse.feishu.cn/share/base/form/shrcn0blCe32lckLKET1LHnBmVf"
        >
          问题反馈
        </a>
      ),
      key: '3',
      icon: <MessageFilled />
    },
    {
      label: (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://lingverse.feishu.cn/share/base/form/shrcnWRuabfQiUQh4wG1ghZ9gzf"
        >
          申请发票
        </a>
      ),
      key: '4',
      icon: <ProjectFilled />
    }
  ]

  const onItemClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '0') {
      eventTracking('BeginnerGuide')
      setPreview({
        url: urlSource('hiflys/新手引导.mp4'),
        title: '新手引导'
      })
    }

    if (key === '1') {
      eventTracking('UserManual')
    }
  }

  const onQaModalClose = () => {
    setQaModalOpen(false)
    localStorage.setItem('qaModal', '1')
  }

  if (location.href.includes('mobile')) return null

  return (
    <>
      {showQuestion && (
        <div className="qa-icon" onClick={() => setQaModalOpen(true)}>
          <img src={QaIcon} />
        </div>
      )}

      <Dropdown
        menu={{ items, onClick: onItemClick }}
        placement="topRight"
        overlayClassName="customer-dropdown"
        trigger={['click']}
        open={open}
        onOpenChange={(open) => {
          if (open) {
            eventTracking('HelpCenter')
          }
          setOpen(open)
        }}
      >
        <div className="customer-service" onClick={(e) => e.preventDefault()}>
          {open ? <CaretDownOutlined /> : <QuestionCircleOutlined />}
          {versionNumber > 0 && <i className="vesion-new">1</i>}
        </div>
      </Dropdown>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />

      <QaModal open={qaModalOpen} onCancel={onQaModalClose} onSuccess={getQaCompleted} />
    </>
  )
}

export default CustomerService
