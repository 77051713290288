import { ConfigProvider, notification } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import { Suspense, useEffect, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'
import * as uuid from 'uuid'
import * as HomeApi from '@/api/home'
import AuthRouter from '@/routers/authRouter'
import Router from '@/routers/index'
import * as Api from './api/account'
import CustomerService from './components/CustomerService'
import { UserStore } from './global-states'
import { urlSource } from './libs/util'

const App = () => {
  const data = useRef<any>()
  const audioRef = useRef<any>()

  useEffect(() => {
    initPage()
    sessionStorage.setItem('activity', '')
    document.addEventListener('visibilitychange', () => {
      document.title = '飞影数字人'
    })
  }, [])

  const initPage = async () => {
    initDeviceId()
    Api.getUserInfo()
    if (!checkPage()) {
      UserStore.checkUserPackage()
    }
    getData()
    setInterval(() => {
      getData()
    }, 10000)
  }

  const destroyAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.remove()
    }
  }

  const checkPage = () => {
    if (location.href.includes('login') || location.href.includes('mobile')) {
      return true
    }
    return false
  }

  const initDeviceId = () => {
    let di = localStorage.getItem('di')
    if (!di) {
      di = `di${uuid.v4()}`
      localStorage.setItem('di', `di-${uuid.v4()}`)
    }
    UserStore.device_id = di
  }

  const getData = async () => {
    if (checkPage()) return
    const result = await HomeApi.getCreations({
      page: 0,
      size: 10
    })
    if (data.current?.length) {
      const creatingIds = data.current.filter((d: any) => d.status === 1).map((d: any) => d.id)
      if ((result?.list || []).some((l: any) => l.status !== 1 && creatingIds.includes(l.id))) {
        if (document.visibilityState === 'hidden') {
          document.title = '作品已生成'
        }
        notification.destroy()
        notification.success({
          message: '作品已生成',
          description: <div>快去作品管理页查看吧！</div>,
          duration: null
        })
        destroyAudio()
        const audio = new Audio()
        audio.src = urlSource('hiflys/notice.mp3')
        audio.play()
        setTimeout(() => {
          audio.muted = true
        }, 2000)
        audioRef.current = audio
      }
    }
    data.current = result?.list || []
  }

  return (
    <BrowserRouter>
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: '#15B8F7'
          },
          components: {
            Menu: {
              itemSelectedBg: '#ecf5ff',
              itemHeight: 48,
              itemActiveBg: '#ecf5ff'
            }
          }
        }}
      >
        <AuthRouter>
          <Suspense>
            <>
              <Router />
              <CustomerService />
            </>
          </Suspense>
        </AuthRouter>
      </ConfigProvider>
    </BrowserRouter>
  )
}
export default App
