import './style.scss'
import { Buffer } from 'buffer'
import { FC, useEffect, useState } from 'react'
import * as homeApi from '@/api/home'
import PlanModal from '@/components/PlanModal'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import VoiceMarketBox from './components/VoiceMarketBox'
import VoiceMarketDetail from './components/VoiceMarketDetail'

const VoiceMarket: FC = () => {
  const [shareTts, setShareTts] = useState<any[]>([])
  const [detail, setDetail] = useState<any>()
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [voiceMarketDetailOpen, setVoiceMarketDetailOpen] = useState(false)

  useEffect(() => {
    eventTracking('VoiceCommunityPage')
    ;(window as any).Buffer = Buffer
    getShareTts()
    UserStore.updateUserPackage()
  }, [])

  const getShareTts = async () => {
    const res = await homeApi.getCommunityVoices()
    setShareTts(res?.list || [])
  }

  const upgradePlan = () => {
    setPlanModalOpen(true)
  }

  return (
    <div className={`page-common page-voice-market ${detail?.id ? 'detail' : ''}`}>
      <div className="common-title">
        <div className="text">声音社区</div>
      </div>
      <div className="voice-market-wrapper">
        <VoiceMarketBox
          onItemClick={(t) => {
            eventTracking('VoiceGroupClick', { group_id: t.id })
            setDetail(t)
            setVoiceMarketDetailOpen(true)
          }}
          list={shareTts}
        />
      </div>
      <VoiceMarketDetail
        open={voiceMarketDetailOpen}
        group={detail}
        upgradePlan={upgradePlan}
        onCancle={() => setVoiceMarketDetailOpen(false)}
      />

      <PlanModal
        open={planModalOpen}
        onCancel={() => setPlanModalOpen(false)}
        onSuccess={() =>
          setTimeout(() => {
            UserStore.updateUserPackage()
          }, 300)
        }
      />
    </div>
  )
}

export default VoiceMarket
