import './style.scss'
import { Button, Input, message, Tooltip } from 'antd'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import EditorPlay from '@/assets/editor-play.png'
import EditorStop from '@/assets/editor-stop.png'
import { eventTracking } from '@/libs/util'
import { LoadingOutlined } from '@ant-design/icons'

interface IProps {
  defaultText?: string
  onPreviewWords: (words: string) => void
  onChange: (value: string) => void
}

const RichText: FC<IProps> = (props) => {
  const { defaultText } = props
  const inputRef = useRef<HTMLDivElement>(undefined as any)
  const [inputText, setInputText] = useState('')
  const lastFocus = useRef<any>()
  const selectHtmlRef = useRef<any>()
  const [previewLoading, setPreviewLoading] = useState(false)
  const [stopSelectShow, setStopSelectShow] = useState(false)
  const [stopTime, setStopTime] = useState(0)
  const stopSelectRef = useRef<HTMLDivElement>(null)
  const stopList = [0.2, 0.3, 0.5, 1, 2]
  const stopEdidRef = useRef<any>()
  const [stopInput, setStopInput] = useState<any>()

  useEffect(() => {
    if (defaultText) {
      inputRef.current.innerText = defaultText
      setInputText(defaultText)
    }

    document.addEventListener('click', onBlur)
    inputRef.current?.addEventListener('keyup', getSelectedHtml)
    inputRef.current?.addEventListener('mouseup', getSelectedHtml)
    inputRef.current?.addEventListener('keydown', onKeyDown)
    inputRef.current?.addEventListener('paste', pasteText)

    return () => {
      selectHtmlRef.current = undefined
      document.removeEventListener('click', onBlur)
      inputRef.current?.removeEventListener('paste', pasteText)
      inputRef.current?.removeEventListener('keyup', getSelectedHtml)
      inputRef.current?.removeEventListener('mouseup', getSelectedHtml)
      inputRef.current?.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  // 纯文本长度
  const textLength = useMemo(() => {
    return inputText.length
  }, [inputText])

  const onBlur = () => {
    setStopSelectShow(false)
  }

  const onKeyDown = (event: KeyboardEvent) => {
    const key = event.key || event.keyCode
    if ((event.metaKey || event.ctrlKey) && (key === 'z' || key === 'y')) {
      event.preventDefault()
    }
  }

  // 输入框值改变
  const onChange = () => {
    lastFocus.current = window.getSelection()?.getRangeAt?.(0)

    const text = (inputRef.current?.innerHTML || '')?.replace(/&nbsp;/g, ' ').trim()
    setInputText(inputRef.current.innerText.trim())
    props.onChange(text)
    if (inputRef.current?.innerText === '') {
      inputRef.current.innerHTML = ''
    }
  }

  // 输入框焦点事件
  const handleInputFocus = () => {
    setStopTime(0)
    setStopSelectShow(false)
    lastFocus.current = window.getSelection()?.getRangeAt(0)
  }

  // 粘贴
  const pasteText = (e: ClipboardEvent) => {
    e.preventDefault()
    const text = e.clipboardData?.getData('text/plain')
    if (inputRef.current) {
      document.execCommand('insertText', false, text)
    }
  }

  // 主动聚焦光标
  const setInputFocus = (range: Range) => {
    const selection = window.getSelection()
    if (selection && range) {
      selection.removeAllRanges()
      range.collapse(true)
      selection.addRange(range.cloneRange())
    }
  }

  // 主动选中内容
  const setRangeSelect = (range: Range) => {
    const selection = window.getSelection()
    if (selection && range) {
      selection.removeAllRanges()
      selection.addRange(range.cloneRange())
    }
  }

  // 选择停顿展示
  const showStopSelect = (range: Range) => {
    if (range && range.startOffset === range.endOffset) {
      const focusNode = window.getSelection()?.focusNode as any
      let left = 0
      let top = 0
      if (focusNode?.offsetTop) {
        left = focusNode.offsetLeft
        top = focusNode.offsetTop
      } else {
        const coordinates = getCaretCoordinates()
        if (coordinates) {
          left = coordinates.left
          top = coordinates.top
        }
      }
      setStopSelectPosition(top, left)
    } else {
      message.warning('请在光标处插入停顿')
    }
  }

  const setStopSelectPosition = (top: number, left: number) => {
    if (stopSelectRef.current) {
      const maxLeft = document.body.clientWidth - stopSelectRef.current.clientWidth - 20
      stopSelectRef.current.style.left = `${Math.min(maxLeft, left - stopSelectRef.current.clientWidth / 2)}px`
      stopSelectRef.current.style.top = `${top + 32}px`
      const arrow = stopSelectRef.current.querySelector('.arrow') as any
      if (arrow) {
        arrow.style.left = `${left - 2}px`
        arrow.style.top = `${top + 28}px`
      }
    }

    setStopSelectShow(true)
  }

  // 添加停顿
  const addStop = (time: number, e: any) => {
    e.stopPropagation()

    if (stopEdidRef.current) {
      setStopTime(time)
      stopEdidRef.current.setAttribute('time-text', `停顿${time}s`)
      stopEdidRef.current.setAttribute('time', `${time}s`)
    } else if (lastFocus.current) {
      const tag = document.createElement('span')
      const breakTag = document.createElement('break')
      breakTag.className = 'break'
      breakTag.setAttribute('time', `${time}s`)
      breakTag.setAttribute('time-text', `停顿${time}s`)
      tag.setAttribute('contentEditable', 'false')
      tag.appendChild(breakTag)
      const spaceTag = document.createElement('span')
      spaceTag.innerText = ' '
      spaceTag.className = 'space'
      lastFocus.current.insertNode(spaceTag)
      lastFocus.current.insertNode(tag)

      tag.onclick = function (e: any) {
        e.stopPropagation()
        const rect = e.target.getBoundingClientRect()
        const { top, left } = rect
        setTimeout(() => {
          setStopSelectPosition(top, left)
        }, 50)
        setStopTime(+(e.target.getAttribute('time')?.split('s')?.[0] || 0))
        setStopInput(undefined)
        stopEdidRef.current = breakTag
      }
    }
    setStopSelectShow(false)
    stopEdidRef.current = undefined
    setStopTime(0)
    onChange()
    setTimeout(() => {
      lastFocus.current = undefined
    }, 200)
  }

  // 删除停顿
  const deleteStop = () => {
    if (stopEdidRef.current) {
      stopEdidRef.current.remove()
      setStopSelectShow(false)
      setStopTime(0)
      setStopInput(undefined)
    }
  }

  // 获取选中元素距窗口距离
  const getCaretCoordinates = () => {
    const selection = window.getSelection()

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt?.(0)
      const rect = range.getBoundingClientRect()
      const coordinates = {
        top: rect.top + window.pageYOffset,
        left: rect.left + window.pageXOffset
      }
      return coordinates
    }

    return null
  }

  // 获取选中的html，包含标签
  const getSelectedHtml = () => {
    const selection = window.getSelection()

    if (selection && selection.toString()) {
      let html = ''
      if (selection.rangeCount) {
        const range = selection.getRangeAt?.(0)
        const container = document.createElement('div')
        container.appendChild(range.cloneContents())
        html = container.innerHTML
      }
      selectHtmlRef.current = html
    } else {
      selectHtmlRef.current = undefined
    }
  }

  // 试听
  const previewWords = async (e: any) => {
    e.stopPropagation()

    eventTracking('audition')

    const words = selectHtmlRef.current?.replace(/<[^>]*>/g, '')?.replace(/&nbsp;/g, ' ') || ''

    if (!words.trim()) {
      return message.warning('鼠标滑动选取文本内容, 进行逐句试听, 最多选取300字')
    }

    if (words.length > 300) {
      return message.warning('逐句试听最多选取300字')
    }

    setRangeSelect(lastFocus.current)
    setPreviewLoading(true)
    await props.onPreviewWords(
      selectHtmlRef.current
        ?.replace(/<(?!\/?(break|phoneme|category)\b)[^>]+>/g, '')
        ?.replace(/<break\s+class="[^"]*"\s+time="([^"]*)"\s+time-text="[^"]*"\s*>/g, '<break time="$1">')
        ?.replace(/&nbsp;/g, ' ')
        ?.replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        ?.replace(/&amp;/g, '&')
        ?.replace(/&nbsp;/g, "'")
        ?.replace(/&quot;/g, '"')
        ?.replace(/<(?!break\b[^>]*>)(?!\/break>)/g, '')
        ?.replace(/(<\/?break\b[^>]*>)|>/g, (match: any, p: any) => {
          // 如果匹配的是 <break> 或 </break>，则保留它
          if (p) return p
          // 否则，移除 '>'
          return ''
        })
    )
    setPreviewLoading(false)
  }

  return (
    <div className="rich-text">
      <div className="rich-text-op">
        <Tooltip title={'鼠标滑动选取文本内容, 进行逐句试听, 最多选取300字'} placement="top">
          <div className="op-item" onClick={previewWords}>
            {!previewLoading ? <img src={EditorPlay} /> : <LoadingOutlined />}

            <span>试听</span>
          </div>
        </Tooltip>
        <Tooltip title={'在光标处调整文字之间的停顿时长'} placement="top">
          <div
            className="op-item"
            onClick={(e) => {
              if (!textLength) {
                return message.warning('请先输入台词')
              }
              e.preventDefault()
              e.stopPropagation()
              setStopInput(undefined)
              eventTracking('insert_pause')
              setInputFocus(lastFocus.current)
              showStopSelect(lastFocus.current)
            }}
          >
            <img src={EditorStop} />
            <span>插入停顿</span>
          </div>
        </Tooltip>
      </div>
      <div
        contentEditable={true}
        ref={inputRef}
        className={`rich-text-input ${textLength > 0 || defaultText ? '' : 'border-red'}`}
        onClick={handleInputFocus}
        data-placeholder="请输入台词"
        onKeyUp={onChange}
        onSelect={() => {
          onChange()
          getSelectedHtml()
        }}
        onMouseUp={onChange}
      ></div>

      <div className="drag" ref={stopSelectRef} style={{ visibility: stopSelectShow ? 'initial' : 'hidden' }}>
        <div className="arrow"></div>
        <div className="drag-content">
          <ul>
            {stopList.map((time, index) => (
              <li
                key={`${time}-${index}`}
                className={stopTime === time ? 'active' : ''}
                onClick={addStop.bind(this, time)}
              >
                停顿{time}秒
              </li>
            ))}
          </ul>
          <Input
            type="number"
            width={'88px'}
            height={24}
            min={0}
            max={10}
            maxLength={4}
            value={stopInput}
            placeholder="自定义"
            onChange={(e) => {
              let value = e.target.value
              if (value?.length >= 4) {
                value = value.substring(0, 4)
              }

              setStopInput(value ? Math.min(Math.max(+value, 0), 10).toString() : value)
            }}
            onKeyPress={(event) => {
              event.stopPropagation()
              if (event.key === 'Enter' && +stopInput) {
                addStop(stopInput, event)
              }
            }}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            onBlur={(event) => {
              setTimeout(() => {
                if (+stopInput && !stopList.includes(stopInput)) {
                  addStop(stopInput, event)
                }
              }, 200)
            }}
          />
          {!!stopTime && <Button onClick={deleteStop}>移除</Button>}
        </div>
      </div>
    </div>
  )
}

export default RichText
