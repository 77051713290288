import { Navigate, useRoutes } from 'react-router-dom'
import LayoutIndex from '@/components/Layout'
import AvatarMarket from '@/views/avatar-market'
import Clone from '@/views/clone'
import CreateVideo from '@/views/create-video'
import Home from '@/views/home/index'
import InteractiveAvatar from '@/views/interactive-avatar'
import Invite from '@/views/invite'
import Login from '@/views/login'
import Mobile from '@/views/mobile'
import Setting from '@/views/setting'
import Video from '@/views/video/index'
import VoiceMarket from '@/views/voice-market'

export const rootRouter = [
  {
    path: '/mobile',
    element: <Mobile />,
    meta: {
      requiresAuth: false,
      title: '移动端访问'
    }
  },
  {
    path: '/login',
    element: <Login />,
    meta: {
      requiresAuth: false,
      title: '登录页'
    }
  },
  {
    path: '/create-video',
    element: <CreateVideo />,
    meta: {
      requiresAuth: false,
      title: '创建视频'
    }
  },
  {
    path: '/create-video/:avatarId',
    element: <CreateVideo />,
    meta: {
      requiresAuth: false,
      title: '创建视频'
    }
  },
  {
    path: '/digital',
    element: <InteractiveAvatar />,
    meta: {
      requiresAuth: true,
      title: '实时互动数字人'
    }
  },
  {
    element: <LayoutIndex />,
    children: [
      {
        path: '/home',
        element: <Home />,
        meta: {
          requiresAuth: false,
          title: '首页'
        }
      },
      {
        path: '/clone',
        element: <Clone />,
        meta: {
          requiresAuth: false,
          title: '声音克隆'
        }
      },
      {
        path: '/market/voice',
        element: <VoiceMarket />,
        meta: {
          requiresAuth: false,
          title: '声音市场'
        }
      },
      {
        path: '/market/digital',
        element: <AvatarMarket />,
        meta: {
          requiresAuth: false,
          title: '数字人市场'
        }
      },
      {
        path: '/avatar-market',
        element: <AvatarMarket />,
        meta: {
          requiresAuth: false,
          title: '数字人市场'
        }
      },
      {
        path: '/video',
        element: <Video />,
        meta: {
          requiresAuth: false,
          title: '视频'
        }
      },
      {
        path: '/invite',
        element: <Invite />,
        meta: {
          requiresAuth: false,
          title: '免费积分'
        }
      },
      {
        path: '/setting',
        element: <Setting />,
        meta: {
          requiresAuth: false,
          title: '个人中心',
          headerShow: true
        }
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/home" />
  }
]

const Router = () => {
  const routes = useRoutes(rootRouter)
  return routes
}

export default Router
