import './style.scss'
import { Button, message, Modal, Slider, SliderSingleProps } from 'antd'
import { Buffer } from 'buffer'
import { FC, memo, useEffect, useRef, useState } from 'react'
import wavConverter from 'wav-converter'
import * as homeApi from '@/api/home'
import { Play } from '@/assets/svg'
import { Render } from '@/libs/util'
import { LoadingOutlined } from '@ant-design/icons'

interface IProps {
  preview: {
    id: number
    display_name: string
    level: number
    voice_parameters: {
      rate: string
      pitch: string
      volume: string
    }
  }
  onCancel?: () => void
  onOk?: (voice_parameters: any) => void
}

const VoiceSettingModal: FC<IProps> = (props) => {
  const { preview, onCancel, onOk } = props
  const [voiceSetting, setVoiceSetting] = useState({
    rate: 50,
    pitch: 50,
    volume: 50
  })
  const audioRef = useRef<any>()

  const rateMarks: SliderSingleProps['marks'] = {
    0: 0.5,
    10: 0.6,
    20: 0.7,
    30: 0.8,
    40: 0.9,
    50: 1.0,
    55: 1.1,
    60: 1.2,
    65: 1.3,
    70: 1.4,
    75: 1.5,
    87.5: 1.75,
    100: 2.0
  }

  const pitchMarks: SliderSingleProps['marks'] = {
    0: 0.1,
    10: 0.2,
    20: 0.4,
    30: 0.6,
    40: 0.8,
    50: 1.0,
    60: 1.2,
    70: 1.4,
    80: 1.6,
    90: 1.8,
    100: 2.0
  }

  const volumeMarks: SliderSingleProps['marks'] = {
    0: 0.1,
    10: 0.2,
    20: 0.4,
    30: 0.6,
    40: 0.8,
    50: 1.0,
    60: 1.2,
    70: 1.4,
    80: 1.6,
    90: 1.8,
    100: 2.0
  }

  useEffect(() => {
    ;(window as any).Buffer = Buffer
    if (preview) {
      const { rate, pitch, volume } = preview.voice_parameters

      setVoiceSetting({
        rate: parseFloat(Object.keys(rateMarks).find((key) => rateMarks[key] == rate) || '50'),
        pitch: parseFloat(Object.keys(pitchMarks).find((key) => pitchMarks[key] == pitch) || '50'),
        volume: parseFloat(Object.keys(volumeMarks).find((key) => volumeMarks[key] == volume) || '50')
      })
    }

    return () => {
      closeAudio()
    }
  }, [preview])

  const onSettingChange = (key: string, val: number) => {
    setVoiceSetting({ ...voiceSetting, [key]: val })
  }

  const closeAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
  }

  const previewTts = async (id: number | string, data: any) => {
    closeAudio()
    const res = await homeApi.previewTts(id, { ...data })
    const audio = new Audio()
    audio.src = `data:audio/wav;base64,${wavConverter
      .encodeWav(new Buffer(res.audio_base64, 'base64'), {
        numChannels: 1,
        sampleRate: 16000,
        byteRate: 32_000
      })
      .toString('base64')}`
    audio.play()
    audioRef.current = audio
  }

  const resetSetting = () => {
    setVoiceSetting({
      rate: 50,
      pitch: 50,
      volume: 50
    })
  }

  const onConfirm = async () => {
    const voice_parameters = {
      rate: `${rateMarks[voiceSetting.rate]}`,
      pitch: `${pitchMarks[voiceSetting.pitch]}`,
      volume: `${volumeMarks[voiceSetting.volume]}`
    }
    await homeApi.updateVoice(preview.id, {
      voice_name: preview.display_name,
      voice_parameters
    })
    message.success('设置成功')
    onOk?.(voice_parameters)
  }

  if (!preview?.id) return null

  return (
    <Modal
      title="声音调整"
      open={!!preview.id}
      width={880}
      footer={null}
      onCancel={() => {
        onCancel?.()
      }}
      className="commom-modal"
    >
      <div className="voice-setting-modal">
        <div className="setting-item">
          <div className="title">语速</div>
          <Slider
            step={100}
            marks={rateMarks}
            value={voiceSetting.rate}
            tooltip={{ open: false }}
            onChange={onSettingChange.bind(this, 'rate')}
          />
        </div>
        {preview.level > 10 && (
          <div className="setting-item">
            <div className="title">语调</div>
            <Slider
              step={100}
              marks={pitchMarks}
              value={voiceSetting.pitch}
              tooltip={{ open: false }}
              onChange={onSettingChange.bind(this, 'pitch')}
            />
          </div>
        )}

        {preview.level > 10 && (
          <div className="setting-item">
            <div className="title">音量</div>
            <Slider
              step={100}
              marks={volumeMarks}
              value={voiceSetting.volume}
              tooltip={{ open: false }}
              onChange={onSettingChange.bind(this, 'volume')}
            />
          </div>
        )}

        <div className="footer">
          <label>发音人：{preview.display_name}</label>
          <div className="op">
            <Render>
              {function Btn() {
                const [loading, settingLoading] = useState(false)

                const play = async () => {
                  try {
                    settingLoading(true)
                    await previewTts(preview.id, {
                      text: '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞',
                      voice_parameters: {
                        rate: '1',
                        pitch: '1',
                        volume: '1'
                      }
                    })
                  } finally {
                    settingLoading(false)
                  }
                }

                return (
                  <Button icon={loading ? <LoadingOutlined /> : <Play />} onClick={play}>
                    默认效果
                  </Button>
                )
              }}
            </Render>

            <div className="split">- - -</div>

            <Render>
              {function Btn() {
                const [loading, settingLoading] = useState(false)

                const play = async () => {
                  try {
                    settingLoading(true)
                    await previewTts(preview.id, {
                      text: '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞',
                      voice_parameters: {
                        rate: `${rateMarks[voiceSetting.rate]}`,
                        pitch: `${pitchMarks[voiceSetting.pitch]}`,
                        volume: `${volumeMarks[voiceSetting.volume]}`
                      }
                    })
                  } finally {
                    settingLoading(false)
                  }
                }

                return (
                  <Button icon={loading ? <LoadingOutlined /> : <Play />} onClick={play}>
                    调整后效果
                  </Button>
                )
              }}
            </Render>
          </div>

          <div className="right">
            <Button onClick={resetSetting}>恢复默认</Button>
            <Button onClick={onConfirm} type="primary">
              确定
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(VoiceSettingModal)
