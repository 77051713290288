import './style.scss'
import { message, Modal } from 'antd'
import { FC, memo } from 'react'
import * as Api from '@/api/home'
import { urlSource } from '@/libs/util'
import { CloseCircleOutlined } from '@ant-design/icons'

interface IProps {
  open: boolean
  couponId?: number
  onSuccess?: () => void
  onCancel?: () => void
}

const Coupon: FC<IProps> = (props) => {
  const { open, couponId, onSuccess, onCancel } = props

  const receiveCoupon = async () => {
    if (couponId) {
      await Api.receviceCoupon(couponId)
      message.success('领取成功')
      onSuccess?.()
      onCancel?.()
    }
  }

  return (
    <>
      <Modal
        maskClosable={false}
        closeIcon={null}
        className={`coupon-modal`}
        onCancel={onCancel}
        footer={null}
        width={300}
        open={open}
      >
        <div className="coupon-modal-content">
          <img className="img-coupon" src={urlSource('hiflys/modal-coupon.webp')} onClick={receiveCoupon} />
          <CloseCircleOutlined onClick={onCancel} />
        </div>
      </Modal>
    </>
  )
}

export default memo(Coupon)
