import './style.scss'
import { Button, Drawer, Input, message, Modal, notification, Radio, Space } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as homeApi from '@/api/home'
import { urlSource } from '@/libs/util'
import { DeleteOutlined } from '@ant-design/icons'
import UploadFile from '../UploadFile'

interface IProps {
  open: boolean
  editId?: number
  onCancel?: () => void
  onOk?: () => void
}

const CreateKnowledgeDrawer: FC<IProps> = (props) => {
  const { open, onCancel, editId, onOk } = props
  const [form, setForm] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [declareChecked, setDeclareChecked] = useState(false)
  const [qaFiles, setQaFiles] = useState<any[]>([])
  // const [docFiles, setDocFiles] = useState<any[]>([])

  useEffect(() => {
    if (open) {
      !editId && showDeclare()
      setForm({
        name: '',
        description: '',
        wake_word: '',
        prompt: ''
      })
      setQaFiles([])
      if (editId) {
        setDeclareChecked(true)
        getDetail()
      }
    }
  }, [open])

  const getDetail = async () => {
    const res = await homeApi.getAgentDetail(editId as number)
    setForm(res || {})
    setQaFiles(res.qa_files || [])
  }

  const showDeclare = () => {
    let index = 3

    const instance = Modal.confirm({
      width: 600,
      title: '使用者承诺须知',
      content: (
        <>
          <div>
            本声明将帮助您更好的在【飞影数字人】平台（下称“本平台”）使用相关工具上传和管理您的作品。您若上传作品，即视为您已充分知悉并充分接受以下内容：
          </div>
          <ul className="declare-list">
            <li>您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。</li>
            <li>
              您在使用本平台及上传、发布作品时，应当自觉遵守国家法律、法规，遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。如有违反，一经本平台发现将根据违规程度采取包括但不限于删除、下架、禁止发布内容、封禁账号等处理方式。如造成恶劣影响或涉嫌违法犯罪的，本平台将有权向有关管理机关或公安机关提交相关内容，并配合进行调查。
            </li>
            <li>
              若您上传的作品及作品中的素材（包括但不限于创意、文本、肖像、音频、图片、视频等）侵犯了任何第三方权利，本平台均有权在收到相关侵权投诉后对该被投诉的作品或用户账号依据相应规则，采取包括但不限于
              <label className="red">下架、警告、封禁账号</label>等处理方式。
            </li>
            <li>
              请勿使用我们的服务克隆或生成任何侵犯版权、违反道德伦理、或违反中华人民共和国法律法规的内容。我们生成的所有内容均带有详细日志，自动/人工复审，以及
              可溯源的隐形视频/音频水印，
              <label className="red">若发现您违反了相关规则，我们保留终止您的服务并上报公安机关等机构的权利。</label>
            </li>
            <li>
              更多信息请参阅
              <a target="_blank" href="/eula.html">
                用户协议
              </a>
              、
              <a target="_blank" href="/privacy_agreement.html">
                隐私协议
              </a>
              。
            </li>
          </ul>
        </>
      ),

      okText: (
        <div>
          我已知晓，同意<label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label>
        </div>
      ),
      cancelText: '取消',
      okButtonProps: {
        disabled: true
      },
      onOk: () => {
        setDeclareChecked(true)
      },
      onCancel: () => {
        setDeclareChecked(false)
        onCancel?.()
      }
    })

    const si = setInterval(() => {
      index = index - 1
      instance.update({
        okText: (
          <div>
            我已知晓，同意{index > 0 ? <label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label> : ''}
          </div>
        )
      })
      if (index < 1) {
        instance.update({
          okButtonProps: {
            disabled: false
          }
        })
        clearInterval(si)
      }
    }, 1000)
  }

  const onFormChange = (key: string, e: any) => {
    setForm({
      ...form,
      [key]: e.target.value
    })
  }

  const onQaUploadSuccess = (data: any) => {
    setQaFiles([
      ...qaFiles,
      {
        ...data
      }
    ])
  }

  const deleteQaFile = (index: number) => {
    setQaFiles(qaFiles.filter((_, i) => i !== index))
  }

  const onConfirm = async () => {
    if (!form.name?.trim()) {
      return message.warning('请输入智能体名字')
    }

    setLoading(true)
    try {
      if (editId) {
        await homeApi.updateAgent(editId, {
          ...form,
          qa_files: qaFiles || []
        })
      } else {
        await homeApi.addAgent({
          ...form,
          qa_files: qaFiles || []
        })
      }

      notification.success({
        message: '提交成功'
      })
      onCancel?.()
      onOk?.()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Drawer
        className="create-drawer"
        title="创建智能体"
        width={720}
        onClose={onCancel}
        open={open}
        footer={
          <>
            <div className="declare">
              <Radio checked={declareChecked} onClick={() => setDeclareChecked(!declareChecked)}></Radio>
              我已阅读并同意
              <label className="link" onClick={showDeclare}>
                《使用者承诺须知》
              </label>
            </div>
            <Space>
              <Button onClick={onCancel}>取消</Button>
              <Button disabled={!declareChecked} type="primary" onClick={onConfirm} loading={loading}>
                确定
              </Button>
            </Space>
          </>
        }
      >
        <div className="main">
          <div className="form-item">
            <div className="title">
              <label className="red">*</label>智能体名称
            </div>
            <Input
              maxLength={20}
              placeholder="请输入智能体名称"
              value={form?.name}
              onChange={onFormChange.bind(this, 'name')}
            />
          </div>
          <div className="form-item">
            <div className="title">唤醒词</div>
            <Input
              maxLength={20}
              placeholder="请输入唤醒词，在对话时唤醒智能体"
              value={form?.wake_word}
              onChange={onFormChange.bind(this, 'wake_word')}
            />
          </div>
          <div className="form-item">
            <div className="title">提示词</div>
            <Input.TextArea
              maxLength={500}
              placeholder="为您创建的智能体写一段提示词。示例：你是一个通用个人助手，拥有广泛的知识和灵活的应变能力。"
              value={form?.prompt}
              onChange={onFormChange.bind(this, 'prompt')}
              rows={3}
            />
          </div>

          <div className="form-item">
            <div className="title">
              问答库
              <a className="link" href={urlSource('hiflys/template/问答库模板.csv')}>
                模板下载
              </a>
            </div>
            {open && <UploadFile onUploadSuccess={onQaUploadSuccess} uploadText="大小：不超过10MB" />}
            <div className="file-list">
              {qaFiles?.map((file, index) => (
                <div key={index} className="file-list__item">
                  <span title={file.name} className="name ellipsis">
                    {file.name}
                  </span>
                  <DeleteOutlined onClick={deleteQaFile.bind(this, index)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default memo(CreateKnowledgeDrawer)
