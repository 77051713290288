import './style.scss'
import { Modal } from 'antd'
import { Buffer } from 'buffer'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import wavConverter from 'wav-converter'
import * as homeApi from '@/api/home'
import ArrowLeft from '@/assets/arrow-left.png'
import AudioTagGood from '@/assets/audio-tag-good-new.png'
import AudioTag from '@/assets/audio-tag-new.png'
import Empty from '@/assets/empty.png'
import { Play } from '@/assets/svg/play'
import { UserStore } from '@/global-states'
import { LoadingOutlined, PauseOutlined } from '@ant-design/icons'

interface IProps {
  open: boolean
  selectTts: any
  onCancel?: () => void
  onSelect?: (tts: any) => void
  upgradePlan?: () => void
}

const VoiceModal: FC<IProps> = (props) => {
  const { selectTts, onCancel, onSelect, open, upgradePlan } = props
  const { userPackage } = UserStore
  const [voiceGroups, setVoiceGroups] = useState<any[]>()
  const [globalVoiceGroups, setGlobalVoiceGroups] = useState<any[]>()
  const [bookVoiceGroups, setBookVoiceGroups] = useState<any[]>()
  const [detailGroup, setDetailGroup] = useState<any>()
  const [detailGroupVoices, setDetailGroupVoices] = useState<any[]>([])
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [previewId, setPreviewId] = useState()
  const [playingId, setPlayingId] = useState()
  const previewIdRef = useRef<any>()
  const audioRef = useRef<any>()
  const tabs = ['我的声音', '公共声音', '我的收藏']
  const showGroups = useMemo(() => {
    if (tabIndex === 0) {
      return voiceGroups?.map((v) => ({ ...v, group_id: v.id }))
    }

    if (tabIndex === 1) {
      return globalVoiceGroups?.map((v) => ({ ...v, group_id: v.id }))
    }

    if (tabIndex === 2) {
      return bookVoiceGroups?.map((v) => ({ ...v, book_id: v.id }))
    }
  }, [voiceGroups, globalVoiceGroups, bookVoiceGroups, tabIndex])

  useEffect(() => {
    if (!open) {
      setTabIndex(0)
      setDetailGroup(undefined)
      closeAudio()
    }
  }, [open])

  useEffect(() => {
    if (selectTts?.group_id && open) {
      getVoiceGroups()
      getGlobalVoiceGroups()
      getBookTts()
    }
  }, [selectTts, open])

  useEffect(() => {
    if (detailGroup?.group_id) {
      getDetailGroupVoices()
    } else {
      setDetailGroupVoices([])
    }
  }, [detailGroup])

  useEffect(() => {
    previewIdRef.current = previewId
  }, [previewId])

  const getVoiceGroups = async () => {
    const res = await homeApi.getSelfTtsGroups()
    setVoiceGroups((res.list || []).filter((l: any) => l?.members?.length))
  }

  const getGlobalVoiceGroups = async () => {
    const res = await homeApi.getGlobalTtsGroups()
    setGlobalVoiceGroups(res.list || [])
  }

  const getBookTts = async () => {
    const res = await homeApi.getBookmarkedCommunityVoice()
    setBookVoiceGroups(res.list || [])
  }

  const getDetailGroupVoices = async () => {
    let res
    if (detailGroup.parent_id || tabIndex === 2) {
      res = await homeApi.getCommunityVoiceList(detailGroup.group_id)
      res.list = res.list || []
    } else {
      res = await homeApi.getVoicGroupDetail(detailGroup.group_id)
    }
    setDetailGroupVoices((res?.list || []).filter((l: any) => l.status === 1))
  }

  const previewTts = async (tts: any, text: string) => {
    if (playingId === tts.id) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }

    setPreviewId(tts.id)
    setPlayingId(undefined)
    clearAudio()

    try {
      let res
      if (tts.parent_id) {
        res = await homeApi.previewCommunityVoice(tts.parent_id, tts.id, {
          text,
          voice_parameters: tts.voice_parameters
        })
      } else {
        res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })
      }
      if (previewIdRef.current !== tts.id) {
        return
      }
      setPlayingId(tts.id)

      clearAudio()

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch {
      closeAudio()
    }
  }

  const clearAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
  }

  const closeAudio = () => {
    clearAudio()
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const vipTip = () => {
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>高保真声音是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        upgradePlan?.()
      }
    })
  }

  const openVipModal = () => {
    Modal.confirm({
      title: '会员专属',
      content: <div>精品声音是会员专属功能，成为会员后可以使用精品声音来创作作品</div>,
      okText: '开通会员',
      cancelText: '取消',
      onOk: () => {
        upgradePlan?.()
      }
    })
  }

  const onBack = () => {
    setDetailGroup(undefined)
    closeAudio()
  }

  const changeVoice = (t: any) => {
    if (userPackage && (userPackage.current_membership_level || 0) < 20 && t.level === 20) {
      return vipTip()
    }

    if ((userPackage?.current_membership_level || 0) < 10 && t.is_premium) {
      return openVipModal()
    }
    setDetailGroup(undefined)
    onSelect?.(t)
    onCancel?.()
  }

  return (
    <>
      <Modal className="tab-voice-modal" onCancel={onCancel} footer={null} width={820} open={open}>
        <div className="tabs">
          {tabs.map((t, index) => (
            <div
              className={`tabs-item ${tabIndex === index ? 'actived' : ''}`}
              key={t}
              onClick={() => {
                setTabIndex(index)
                setDetailGroup(undefined)
              }}
            >
              {t}
            </div>
          ))}
        </div>
        <div className="voice-modal-content">
          {!detailGroup?.group_id ? (
            <>
              <div className="voice-group-list">
                {showGroups && (
                  <>
                    {' '}
                    {showGroups?.length ? (
                      <>
                        {showGroups.map((g) => (
                          <div
                            className={`voice-group-list__item ${
                              g.members?.find((m: any) => m.voice_name === selectTts.name) ? 'actived' : ''
                            }`}
                            key={g.id}
                            onClick={() => {
                              setDetailGroup({
                                ...g,
                                group_id: g.id,
                                group_title: g.title
                              })
                            }}
                          >
                            <div className="left">
                              <img src={g.cover_url} />
                            </div>
                            <div className="right">
                              <div className="name ellipsis">{g.title}</div>
                              <div className="style">{g?.members?.length || 0}种风格</div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="data-empty">
                        <img className="empty" src={Empty} />
                        <p>暂无声音</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="voice-detail">
              <div className="title">
                <img src={ArrowLeft} onClick={onBack} className="arrow-left" />
                <label>{detailGroup?.group_title}</label>
              </div>
              <div className="detail-list">
                {detailGroupVoices.map((t) => (
                  <div
                    className={`detail-list__item ${selectTts.name === t.name ? 'actived' : ''}`}
                    key={t.id}
                    onClick={changeVoice.bind(this, t)}
                  >
                    <div
                      className="left"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (userPackage && (userPackage.current_membership_level || 0) < 20 && t.level === 20) {
                          return vipTip()
                        }
                        previewTts(t, t.preview_text || '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。')
                      }}
                    >
                      {previewId === t.id ? (
                        playingId === t.id ? (
                          <PauseOutlined />
                        ) : (
                          <LoadingOutlined />
                        )
                      ) : (
                        <Play className="play" />
                      )}
                    </div>
                    <div className="center">
                      <div className="name ellipsis">{t.display_name}</div>
                    </div>

                    <div className="right">
                      {t.is_premium && <img src={AudioTagGood} />}
                      {t.level === 20 && <img src={AudioTag} />}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default memo(VoiceModal)
