export const IconKnowledge = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M14.25 7.875L16.125 11.25L14.25 11.625V13.875H13.125L10.875 13.5L10.5 16.125H4.875L4.125 12.2321C2.97029 11.1385 2.25 9.59089 2.25 7.875C2.25 4.56127 4.93627 1.875 8.25 1.875C11.5637 1.875 14.25 4.56127 14.25 7.875Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.375 7.125C6.375 6.08948 7.21448 5.25 8.25 5.25C9.28552 5.25 10.125 6.08948 10.125 7.125C10.125 8.16052 9.28552 9 8.25 9V10.125"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.25 12.375V12.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
